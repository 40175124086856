<template>
    <div>
        <div class="assets-tab--empty" v-if="filteredUserAssets.length === 0">
            <div class="ebx-icon">
                <img :src="assets.icons.emptyStateAssets" alt="No assets">
            </div>
            <template v-if="filterBy == 'org'">
                <p class="ebx-primary">Upload images to use across your projects</p>
            </template>
            <template v-else>
                <p class="ebx-primary">Upload images to use across your projects</p>
            </template>
        </div>
        <div v-if="userDatasetsLoading === false" class="asset-card--container">
            <div class="asset-card--item asset-card--item__action" v-for="(asset,i) in filteredUserAssets" :key="asset.id + '_' + asset.location + '_' + i">
                <div class="asset-card--item-body" @click="handleShowAsset(asset)">
                    <div class="asset-card--item-title">
                        <div class="asset-card--thumbnail">
                            <img :src="getImageUrl(asset)" alt="Folder">
                        </div>
                        <div class="ebx-primary-bold">
                            {{ asset.title }}
                        </div>
                    </div>
                </div>
                <div class="asset-card--item-actions">
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact" v-bind="props"></v-btn>
                        </template>
                        <v-list>
                            <template v-if="userDatasetCanEdit(asset.id, user.uid)">

                                <template v-if="canShareWorkflows">
                                    <v-list-item 
                                        v-if="userDatasetLocation(asset.id) === 'user'" 
                                        prepend-icon="mdi-folder-move-outline"
                                        @click="moveDataset(asset)">
                                        <v-list-item-title class="text-body-2">Move to Team Assets</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item 
                                        v-if="userDatasetLocation(asset.id) === 'org'"
                                        prepend-icon="mdi-folder-move-outline"
                                        @click="moveDataset(asset)">
                                        <v-list-item-title class="text-body-2">Move to My Assets</v-list-item-title>
                                    </v-list-item>
                                </template>

                                <v-list-item 
                                    prepend-icon="mdi-pencil-outline"
                                    @click="handleShowAsset(asset)">
                                    <v-list-item-title class="text-body-2">Edit</v-list-item-title>
                                </v-list-item>
                                <v-list-item 
                                    prepend-icon="mdi-delete-outline"
                                    @click="handleRemoveAsset(asset)">
                                    <v-list-item-title class="text-body-2">Delete</v-list-item-title>
                                </v-list-item>
                            </template>
                            <template v-else>
                                <v-list-item 
                                    prepend-icon="mdi-pencil"
                                    @click="handleShowAsset(asset)">
                                    <v-list-item-title class="text-body-2">View</v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </div>

        <!-- Needed to render the above for some reason -->
        <span v-for="(asset, i) in filteredUserAssets" :key="'dummy_' + i + '_' + asset.id"></span>


        <confirmation-modal ref="modal" :hide-title="!showPermission" :ok-button-text="buttonText" :if-warning="false">
            <template v-slot:title>
                <div class="ebx-header-2">Permissions</div>
            </template>
            <div v-if="!showPermission">
                {{confirmMessage}}
            </div>
            <div v-if="showPermission">
                <md-radio v-model="movePermission" value="viewable" class="ebx-md-radio">Team members can only view</md-radio>
                <md-radio v-model="movePermission" value="editable" class="ebx-md-radio ebx-md-radio-editable">Team members can edit</md-radio>
            </div>
        </confirmation-modal>
    </div>
</template>

<script>
import authMixin from '@/components/mixins/authMixin';
import userDatasetsMixin from '@/components/mixins/userDatasetsMixin';
import { sharedWorkflowMixin } from '@/components/mixins/projectMixin.js'
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import assetsMixin from '@/components/mixins/assetsMixin.js'
import { matchSorter } from 'match-sorter';

export default {
    name: 'UserDatasetsList',
    components: {
        ConfirmationModal
    },
    mixins: [
        authMixin,
        userDatasetsMixin,
        sharedWorkflowMixin,
        assetsMixin
    ],
    props: {
        filterBy: {
            type: String,
            default: 'all'
        },
        searchInput: {
            type: String,
            default: ''
        }
    },
    emits: ['remove-asset'],
    data() {
        return {
            confirmMessage: '',
            movePermission: 'viewable',
            showPermission: true,
            buttonText: "Share",
        }
    },
    methods: {
        getImageUrl(asset) {
            return asset.thumbnail_url ? asset.thumbnail_url : this.assets.icons.datasetImagePlaceholder
        },
        handleShowAsset(asset) {
            this.$router.push({ name: 'Projects-Dataset', params: { datasetId: asset.id } })
        },
        handleRemoveAsset(asset) {
            this.$emit('remove-asset', asset)
        },
        async moveDataset(asset) {
            
            if(this.userDatasetLocation(asset.id) === 'user') {
                this.buttonText = "Share"
                this.confirmMessage = `Are you sure you want to share this dataset with the team?`
                this.showPermission = true
                this.permission = 'viewable'
            } else {
                this.buttonText = "Remove access"
                this.confirmMessage = `Are you sure you want to remove team access to this dataset? Any workflows using this dataset in team projects, or your team’s private projects will no longer run.`
                this.showPermission = false
                this.permission = 'editable'
            }
            
            const confirmed = await this.$refs.modal.confirm();
            if (!confirmed) return;

            await this.$store.dispatch('userdatasets/moveDataset', {
                datasetId: asset.id,
                permission: this.movePermission
            })
        }
    },
    computed: {
        userAssets() {
            // filter out assets that don't have COMPLETED status
            return this.imageCollectionMetadata
        },
        filteredUserAssets() {
            let filteredUserAssets = this.userAssets

            if (this.filterBy === 'org') {
                filteredUserAssets = filteredUserAssets.filter(content => this.userDatasetLocation(content.id) === 'org')
            } else if (this.filterBy === 'user') {
                filteredUserAssets = filteredUserAssets.filter(content => this.userDatasetLocation(content.id) === 'user')
            }

            if (this.searchInput.length > 0) {
                filteredUserAssets = matchSorter(filteredUserAssets, this.searchInput, { keys: ['title', 'keywords'] })
            }

            return filteredUserAssets
        }
    }
}
</script>