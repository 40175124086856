<template>
    <EBXMenuLayout>
        <div v-if="user">
            <h1 class="ebx-header-1 mb-6">{{ orgName }} admin</h1>
            <v-tabs class="border-opacity border-b-sm" :direction="tabDirection">
                <v-tab to="/admin/users">People</v-tab>
                <v-tab to="/admin/organisation/settings">Settings</v-tab>
                <v-tab v-if="isSuperAdmin || isCustomerSupport" to="/admin/organisations">Organisations</v-tab>
                <v-tab v-if="isSuperAdmin || isCustomerSupport" to="/admin/debugger">Debugger</v-tab>
            </v-tabs>
            <div class="mt-6">
                <router-view></router-view>
            </div>
        </div>
    </EBXMenuLayout>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import authMixin from '@/components/mixins/authMixin.js';
import EBXMenuLayout from '../layouts/EBXMenuLayout.vue';

export default {
    name: "Admin",
    mixins: [authMixin],
    components: {
        EBXMenuLayout
    },
    computed: {
        tabDirection() {
            return this.$vuetify.display.smAndUp ? 'horizontal' : 'vertical';
        }
    }
};
</script>