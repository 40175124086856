<template>
    <div class="reset-password">
        <h1 class="ebx-header-1">Reset password</h1>
        <form id="this.form_id" @submit.prevent="resetPassword">
            <div class="md-layout">
                <div class="md-layout-item">
                    <md-field :class="getValidationClass('email')">
                        <label for="email">Email</label>
                        <md-input
                            type="email"
                            name="email"
                            id="email"
                            autocomplete="email"
                            v-model="email"
                        />
                        <span class="md-error" v-if="v$.email.required.$invalid">
                            The email is required
                        </span>
                        <span class="md-error" v-else-if="v$.email.email.$invalid">
                            Invalid email
                        </span>
                    </md-field>

                    <v-btn type="submit" color="primary mt-6"
                        >Reset Password</v-btn>
    
                </div>
            </div>
        </form>
        <md-snackbar
            :md-position="'center'"
            :md-duration="5000"
            v-model:md-active="showSnackbar"
            md-persistent
        >
            <span>{{ errorMessage }}</span>
        </md-snackbar>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { AuthService } from "../services/auth.service";

export default {
    name: "ResetPassword",
    setup: () => ({ v$: useVuelidate() }),
    data: () => ({
        email: "",
        errorMessage: "",
        showSnackbar: false,
    }),
    validations: {
        email: {
            required,
            email,
        },
    },
    methods: {
        getValidationClass(fieldName) {
            const field = this.v$[fieldName];
            if (field) {
                return {
                    "md-invalid": field.$invalid && field.$dirty,
                };
            }
        },
        resetPassword() {
            this.v$.$touch();

            let self = this;
            self.errorMessage = "";

            if (!this.v$.$invalid) {
                AuthService.resetPassword(this.email)
                    .then(() => {
                        // this.email = "";
                        this.errorMessage =
                            "Reset password email has been sent";
                        this.showSnackbar = true;

                        setTimeout(() => {
                            this.$router.push({ name: "SignIn" });
                        }, 3000);
                    })
                    .catch((error) => {
                        this.email = "";
                        this.errorMessage = error.message;
                        this.showSnackbar = true;
                    });
            }
        },
    },
};
</script>

<style scoped>
.reset-password {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
</style>