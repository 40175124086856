const invalidCharactersRegex = '<[^<>]*>?';

export default {
    computed: {
        globalPackages() {
            const allGlobalPackages = this.$store.state.packages.globalPackages || [];
            const orgGlobalPackageVisibility = this.$store.getters['auth/orgGetGlobalPackageVisibility'];
            return allGlobalPackages.filter(pkg => {
                if(orgGlobalPackageVisibility.visible.includes(pkg.id)) {
                    return true;
                }
                if(orgGlobalPackageVisibility.hidden.includes(pkg.id)) {
                    return false;
                }
                // if package id is not in visible or hidden, and hide_new_packages is true, hide the package otherwise show it
                return !orgGlobalPackageVisibility.hide_new_packages;
            })
        },
        teamPackages() { 
            return this.$store.state.packages.teamPackages;
        },
        globalProjects() {
            return this.$store.state.packages.globalProjects;
        },
        teamProjects() {
            return this.$store.state.packages.teamProjects;
        },
        globalProjectsTags() { 
            return this.$store.state.packages.globalProjectsTags; 
        }, 
        showPackageModal() { 
            return this.$store.state.packages.showModal;
        },
        packageModalType() {
            return this.$store.state.packages.modalType;
        },
        isNewPackage() {
            return this.$store.state.packages.addNewPackage;
        },
        packageToEdit() {
            return this.$store.state.packages.packageToEdit;
        },
        projectsToAdd() {
            return this.$store.state.packages.projectsToAdd;
        },
    },
    methods: {
        getPackageById(id) {
            return this.$store.getters['packages/getPackageById'](id);
        },
        setShowPackageModal(showModal) { 
            this.$store.commit('packages/setShowModal', showModal)
        },
        setPackageModalType(type) { 
            this.$store.commit('packages/setModalType', type)
        },
        setAddNewPackage(addNewPackage) {
            this.$store.commit('packages/setAddNewPackage', addNewPackage)
        },
        setPackageToEdit(pkg) {
            this.$store.commit('packages/setPackageToEdit', pkg)
        },
        setProjectsToAdd(projects) {
            this.$store.commit('packages/setProjectsToAdd', projects)
        },
        getTemplateById(id) {
            const templates = [].concat(this.globalProjects, this.teamProjects);
            return templates.find(t => t.id === id);
        },
        validatePackageName(name, type, id = null) {
            let packagesNames = [];
            let error = {};
            if (!name) {
                error.message = 'Please add a name';
                error.exists = true;
                return error;
            }
            // If we are editing a package, we need to exclude the current package name from the validation
            if (type === 'global') {
                if (id) {
                    packagesNames = this.globalPackages.filter(pkg => pkg.id !== id).map(pkg => pkg.title);
                } else {
                    packagesNames = this.globalPackages.map(pkg => pkg.title);
                }
            } else if (type === 'team') {
                if (id) {
                    packagesNames = this.teamPackages.filter(pkg => pkg.id !== id).map(pkg => pkg.title);
                } else {
                    packagesNames = this.teamPackages.map(pkg => pkg.title);
                }
            }
            if (packagesNames) {
                const packageExists = packagesNames.includes(name.trim());
                if (packageExists) {
                    error.message = 'A package with this name already exists, please choose a different name';
                    error.exists = true;
                } else if (name.length > 200) {
                    error.message = 'Name must be below 200 characters';
                    error.exists = true;
                } else if (name.match(invalidCharactersRegex)) {
                    error.message = 'Name cannot contain HTML';
                    error.exists = true;
                }
            }
            return error;
        },
        validatePackageDescription(description) {
            let error = {};
            if (description.length > 5000) {
                error.message = 'Description must be below 5000 characters';
                error.exists = true;
            } else if (description.match(invalidCharactersRegex)) {
                error.message = 'Description cannot contain HTML';
                error.exists = true;
            }
            return error;
        },
        async getGlobalPackages() {
            return this.$store.dispatch('packages/getGlobalPackages');
        },
        async getTeamPackages(orgId) { 
            return this.$store.dispatch('packages/getTeamPackages',orgId);
        },
        async getGlobalProjects() {
            return this.$store.dispatch('packages/getGlobalProjects');
        },
        async getTeamProjects(orgId) {
            return this.$store.dispatch('packages/getTeamProjects', orgId);
        },
        async getGlobalProjectsTags() { 
            return this.$store.dispatch('packages/getGlobalProjectsTags')
        },
        async createPackage(data) {
            return this.$store.dispatch('packages/createPackage', data);
        },
        async updatePackage(id, data) {
            return this.$store.dispatch('packages/updatePackage', { id, data });
        },
        async deletePackage(id) {
            return this.$store.dispatch('packages/deletePackage', id);
        },
    }
}