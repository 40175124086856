<template>
    <div class="ebx-inline-input" :class="inputModifier">
        <div v-if="!isEditing || disabled" class="ebx-inline-input--view-only" @click="enterEditMode" @keyup.enter="enterEditMode">
            <h1 :class="inputStyleClasses" v-if="isTitle">{{value}}</h1>
            <span v-else-if=" !isTitle && !valueIsNullable" :class="inputStyleClasses">
                <slot name="view-value" v-bind:value="value">
                    {{value}}
                </slot>
            </span>
            <span v-else-if="!disabled" class="ebx-primary ebx-primary text-disabled">{{inputPlaceholder || 'Click to add data'}}</span>
            <span v-if="!disabled" class="ebx-inline-input--edit-indicator material-icons-outlined ebx-icon">mode_edit_outline</span>
        </div>
        <div class="ebx-inline-input--input-field" :class="editActionsPosition" v-else>
            <div class="ebx-inline-input--field-container"> 
                <input 
                    ref="input"
                    v-model="newValue"
                    :type="inputType" 
                    :class="inputStyleClasses.concat(inputEditStyleClasses)"
                    @keyup.enter.prevent="saveFieldAction"
                    @blur="blurFieldAction"
                    @keydown.escape.prevent
                    @keyup.escape.prevent="cancelFieldAction"
                />
                <slot name="after-input" v-bind:value="value" v-bind:newValue="newValue" v-bind:focusInput="focusInput" v-bind:enterEditMode="enterEditMode"></slot>
                <div :class="editActions">
                    <button class="ebx-icon-button" @click="saveFieldAction" :class="computedInputValidation.active ? 'ebx-icon-button__disabled' :  ''">
                        <span class="material-icons-outlined ebx-icon">check</span>
                    </button>
                    <button class="ebx-icon-button" @click="cancelFieldAction" ref="cancelButton">
                        <span class="material-icons-outlined ebx-icon">close</span>
                    </button>
                </div>
            </div>
            
            <div class="ebx-inline-input--error" v-if="computedInputValidation.active">
                <span class="material-icons-outlined ebx-icon ebx-icon__small ebx-icon__error">warning_amber</span>
                <span class="ebx-secondary ebx-secondary text-negative ebx-inline-input--error-text">{{computedInputValidation.error}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {globalEventBus} from '@/eventbus'
import valueMixin from '@/components/mixins/valueMixin'
export default {
    props: {
        modelValue: {
            type: String,
            required: true
        },
        disabled:{
            type: Boolean,
            required: false,
            default: false
        },
        editActionsPosition: {
            type: String,
            required: false,
            default: 'right'
        },
        fieldBeingEdited: {
            type: String,
            required: false
        },
        id: {
            type: String,
            required: true
        },
        inputModifier: {
            type: String,
            required: false
        },
        inputPlaceholder: {
            type: String,
            required: false
        },
        inputType: {
            type: String,
            required: false,
            default: 'text'
        },
        inputStyleClasses: {
            type: Array,
            required: false,
            default: () => []
        },
        inputEditStyleClasses: {
            type: Array,
            required: false,
            default: () => []
        },
        inputValidation: {
            type: [Object, Function],
            required: false
        },
        updateValue: {
            type: Boolean,
            required: false,
            default: true
        }, 
        isTitle: { 
            type: Boolean,
            required: false,
            default: false
        },
        disableBlur: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'editing-ebx-field',
        'save'
    ],
    data() {
        return {
            isEditing: false,
            isInvalid: false,
            oldValue: '',
            tempValue: null
        }
    },
    computed: {
        valueIsNullable() {
            return this.value === null || this.value === undefined || '' + this.value === '';
        },
        newValue: {
            get() {
                if(this.updateValue) {
                    return this.value;
                }
                return this.tempValue
            },
            set(val) {
                if(this.updateValue) {
                    this.$emit('update:modelValue', val);
                }else {
                    this.tempValue = val;
                }
            }
        },
        computedInputValidation() {
            if (typeof this.inputValidation === 'function') {
                return this.inputValidation(this.newValue, this.oldValue);
            }
            return this.inputValidation;
        },
        editingDifferentField() {
            if (this.fieldBeingEdited && this.fieldBeingEdited !== this.id) {
                return true;
            }
            return false
        }, 
       editActions() { 
            const classes = ['ebx-header-1', 'ebx-header-2']
            if (classes.some(element => this.inputStyleClasses.includes(element)) || this.isTitle) { 
                return 'ebx-inline-input--edit-title-actions'
            } else { 
                return 'ebx-inline-input--edit-actions'
            }
        }
    },
    created() {
        globalEventBus.$on('page-exit', (field) => {
            if (field === this.id) {
                if(this.inputValidation.active) {
                    this.cancelFieldAction();
                } else {
                    this.saveFieldAction();
                }
            }
        });
    },
    methods: {
        enterEditMode() {
            if (this.editingDifferentField) {
                alert('Another field is being edited. Please save or cancel that field first.');
                return;
            }
            if (this.disabled) {
                return;
            }
            // take a copy of the current value
            this.oldValue = this.value;
            if(this.updateValue === false) {
                this.tempValue = this.value;
            }
            this.isEditing = true;
            // move focus into the input field
            setTimeout(() => {
                this.focusInput();
            });
            this.$emit('editing-ebx-field', this.id);
        },
        blurFieldAction(event){ 
            if(this.disableBlur) {
                return
            }
            const relatedTarget = event.relatedTarget;
            if (this.$refs.cancelButton === relatedTarget) { 
                this.cancelFieldAction()
            } else {
            this.saveFieldAction()
            }
        },
        saveFieldAction() {
            if (this.disabled) {
                return;
            }
            if (this.computedInputValidation.active) {
                return;
            }
            this.isEditing = false;
            this.$emit('editing-ebx-field', '');
            this.$emit('save', this.newValue, this.oldValue);
            this.tempValue = null;
        },
        cancelFieldAction() {
            this.newValue = this.oldValue;
            this.isEditing = false;
            this.$emit('editing-ebx-field', '');
            this.tempValue = null;
        },
        focusInput() {
            this.$refs.input.click();
            this.$refs.input.focus();
        }
    }
}
</script>