<template>
    <div class="area-variable">
        <!-- explorer version -->
        <VariableDetailExplorer
            :variable="variable"
            :are-warnings="areWarnings"
            >
            <div v-if="selectedArea" class="area-variable--selected-area">
                <div class="area-variable--selection-card">
                    <div class="area-variable--area">
                        <div class="roundel" :style="{'background-color': selectedArea.colour}"></div>
                        <p class="ebx-secondary">{{ selectedArea.name }}</p>
                    </div>
                    <div class="area-variable--action">
                        <v-menu v-if="isDrawn">
                            <template v-slot:activator="{ props }">
                                <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="plain" density="compact" v-bind="props" :disabled="disabled"></v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    prepend-icon="mdi-vector-polyline"
                                    @click="handleEditArea">
                                    <v-list-item-title class="text-body-2">Edit shapes</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    prepend-icon="mdi-tray-arrow-down"
                                    @click="exportPolygons">
                                    <v-list-item-title class="text-body-2">Download</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    prepend-icon="mdi-delete-outline"
                                    @click="handleRemoveArea">
                                    <v-list-item-title class="text-body-2">Remove</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <button v-else @click="handleRemoveArea" class="ebx-icon-button ebx-icon-button__no-shadow"><span class="material-icons-outlined ebx-icon">delete</span></button>
                    </div>
                </div>
            </div>
            <v-menu v-else location="right">
                <template v-slot:activator="{ props }">
                    <v-btn 
                        variant="tonal" 
                        color="primary" 
                        v-bind="props"
                        min-width="100%"
                        class="my-3"
                        :disabled="areWarnings || disabled">Select area</v-btn>
                </template>
                <v-list>
                    <v-list-item
                        prepend-icon="mdi-pencil-outline"
                        @click="handleDrawArea">
                        <v-list-item-title class="text-body-2">Draw area</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        prepend-icon="mdi-folder-outline"
                        @click="handleChooseArea">
                        <v-list-item-title class="text-body-2">Choose from assets</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        prepend-icon="mdi-tray-arrow-up"
                        @click="handleUploadArea">
                        <v-list-item-title class="text-body-2">Upload area</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <button 
                v-if="!selectedArea" 
                @click="handleSelectDefaultArea"
                :disabled="areWarnings"
                class="ebx-action-text ebx-action-text--primary"
                ><p>Use example area</p></button>

        </VariableDetailExplorer>
    </div>
</template>

<script>
import VariableDetailExplorer from '@/components/Variables/VariableDetail/VariableDetailExplorer.vue'
export default {
    name: 'AreaVariableExplorer',
    components: {
        VariableDetailExplorer
    },
    props: {
        variable: {
            type: Object,
            required: true
        },
        selectedArea: {
            type: Object,
            required: false,
            default: null
        },
        hasValue: {
            type: Boolean,
            required: false,
            default: false
        },
        areWarnings : { 
            required: false, 
            default: false
        },
        isDrawn: {
            type: Boolean,
            default: false,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    emits: [
        'choose-from-assets',
        'remove-area',
        'select-default-area',
        'draw-area',
        'edit-drawn-area',
        'upload-area',
        'export-polygons'
    ],
    data() {
        return {
        }
    },
    methods: {
        handleChooseArea() {
            this.$emit('choose-from-assets');
        },
        handleRemoveArea() {
            this.$emit('remove-area', true);
        },
        handleSelectDefaultArea() {
            this.$emit('select-default-area');
        },
        handleDrawArea() {
            this.$emit('draw-area');
        },
        handleEditArea() {
            this.$emit('edit-drawn-area');
        },
        handleUploadArea(){
            this.$emit('upload-area');
        },
        exportPolygons(){
            this.$emit('export-polygons', this.selectedArea);
        }
    }
}
</script>