export const styleguideConstants = {
    /**
     * TYPOGRAPHY
     */
    ebx_header_1: '<h1 class="ebx-header-1">ebx-header-1</h1>',
    ebx_header_2: '<h2 class="ebx-header-2">ebx-header-2</h2>',
    ebx_header_3: '<h3 class="ebx-header-3">ebx-header-3</h3>',
    ebx_header_4: '<h4 class="ebx-header-4">ebx-header-4</h4>',
    ebx_primary: '<p class="ebx-primary">ebx-primary</p>',
    ebx_primary_text_invert: '<p class="ebx-primary text-primary-invert">ebx-primary text-primary-invert</p>',
    ebx_primary_text_disabled: '<p class="ebx-primary text-disabled">ebx-primary text-disabled</p>',
    ebx_body_strong: '<p class="ebx-primary">ebx-body <strong>strong</strong></p>',
    ebx_primary_bold: '<p class="ebx-primary-bold">ebx-primary-bold</p>',
    ebx_secondary: '<p class="ebx-secondary">ebx-secondary</p>',
    ebx_secondary_text_invert: '<p class="ebx-secondary text-primary-invert">ebx-secondary text-primary-invert</p>',
    ebx_secondary_text_negative: '<p class="ebx-secondary text-negative">ebx-secondary text-negative</p>',
    ebx_tertiary: '<p class="ebx-tertiary">ebx-tertiary</p>',
    ebx_action: '<p class="ebx-action">ebx-action</p>',
    ebx_ul: `
        <ul class="ebx-ul">
            <li class="ebx-primary">ebx-ul element</li>
            <li class="ebx-primary">ebx-ul el</li>
            <li class="ebx-primary">ebx-ul</li>
        </ul>`,
    /**
     * ICONS
     */
    ebx_icon: '<span class="material-icons-outlined ebx-icon">delete</span>',
    ebx_icon_medium: '<span class="material-icons-outlined ebx-icon ebx-icon__medium">delete</span>',
    ebx_icon_small: '<span class="material-icons-outlined ebx-icon ebx-icon__small">delete</span>',
    ebx_icon_error: '<span class="material-icons-outlined ebx-icon ebx-icon__error">delete</span>',
    /**
     * BUTTONS
     */
    ebx_run_button_pine: `<div class="ebx-run-button">
                    <button type="button" :disabled="runButtonDisabled" name="Run" class="ebx-run-button--pine">
                        <span class="ebx-action">Run</span>
                        <img class="button-icon" :src="assets.icons.play" alt="Toggle expanded section">
                    </button>
                    <!-- Workflow settings menu and trigger -->
                    <v-menu location="top">
                        <template v-slot:activator="{ props }">
                            <button class="ebx-run-button--options" v-bind="props">
                                <div class="carat">
                                    <img :src="assets.icons.carat" alt="Toggle expanded section">
                                </div>
                            </button>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="handleProjectSettings">
                                <v-list-item-title class="text-body-2">Workflow settings</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>`,
    ebx_run_button_pine_disabled: `<div class="ebx-run-button">
                    <button type="button" disabled name="Run" class="ebx-run-button--pine">
                        <span class="ebx-action">Run</span>
                        <img class="button-icon":src="assets.icons.playDisabled" alt="Toggle expanded section">
                    </button>
                    <!-- Workflow settings menu and trigger -->
                    <v-menu location="top">
                        <template v-slot:activator="{ props }">
                            <button class="ebx-run-button--options" v-bind="props">
                                <div class="carat">
                                    <img :src="assets.icons.carat" alt="Toggle expanded section">
                                </div>
                            </button>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="handleProjectSettings">
                                <v-list-item-title class="text-body-2">Workflow settings</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>`,
    ebx_run_button_error: `<div class="ebx-run-button">
                    <button name="Stop" type="button" class="ebx-run-button--error">
                        <span class="ebx-action">Stop</span>
                        <img class="button-icon" :src="assets.icons.stop" alt="Toggle expanded section">
                    </button>
                    <!-- Workflow settings menu and trigger -->
                    <v-menu location="top">
                        <template v-slot:activator="{ props }">
                            <button class="ebx-run-button--options" v-bind="props">
                                <div class="carat">
                                    <img :src="assets.icons.carat" alt="Toggle expanded section">
                                </div>
                            </button>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="handleProjectSettings">
                                <v-list-item-title class="text-body-2">Workflow settings</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>`,
    ebx_button_primary: '<button class="ebx-button ebx-button--primary">Primary</button>',
    ebxbutton_primary: `<EbxButton :theme="'primary'">EbxButton - Primary</EbxButton>`,
    ebx_button_primary_warning: '<button class="ebx-button ebx-button--primary ebx-button--primary__warning">Primary Warning</button>',
    ebx_button_primary_disabled: '<button class="ebx-button ebx-button--primary__disabled">Primary Disabled</button>',
    ebxbutton_primary_disabled: `<EbxButton :theme="'primary'" :disabled="true">EbxButton - Disabled</EbxButton>`,
    ebx_button_secondary: '<button class="ebx-button ebx-button--secondary">Secondary</button>',
    ebx_button_secondary_highlight: '<button class="ebx-button ebx-button--secondary ebx-button--secondary__highlight">highlight</button>',
    ebx_button_secondary_compact: '<button class="ebx-button ebx-button--secondary ebx-button--secondary__compact">compact</button><button class="ebx-button ebx-button--secondary ebx-button--secondary__compact ebx-button--secondary__highlight">compact + highlight</button>',
    ebxbutton_secondary: `<EbxButton :theme="'secondary'">EbxButton - Secondary</EbxButton>`,
    ebx_button_secondary_disabled: '<button class="ebx-button ebx-button--secondary__disabled">Secondary Disabled</button>',
    ebxbutton_secondary_disabled: `<EbxButton :theme="'secondary'" :disabled="true">EbxButton - Secondary</EbxButton>`,
    ebx_button_spinner_disabled: `
        <button class="ebx-button ebx-button--spinner ebx-button--spinner__disabled">
            <md-progress-spinner class="md-accent" md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>
            Loading
        </button>`,
    ebx_link: '<button class="ebx-link">Ebx link</button>',
    ebx_link_light: `<button class="ebx-link ebx-link__light">Ebx link light</button>`,
    close_button: `
        <div class="close-button">
            <md-button class="md-primary"><md-icon>close</md-icon></md-button>
        </div>
    `,
    ebx_action_text: `
        <div class="ebx-action-text">
            <p>Back to Assets</p>
        </div>`,
    ebx_action_text_primary: `
        <div class="ebx-action-text ebx-action-text--primary">
            <p>Back to Assets</p>
        </div>`,
    ebx_action_text_primary_icon:`
        <div class="ebx-action-text ebx-action-text--primary ebx-action-text__icon">
            <span class="material-icons-outlined ebx-icon">chevron_left</span>
            <p>Back to Assets</p>
        </div>`,
    ebx_action_text_primary_icon_right:`
    <div class="ebx-action-text ebx-action-text--primary ebx-action-text__icon">
        <p>Back to Assets</p>
        <span class="material-icons-outlined ebx-icon">chevron_right</span>
    </div>`,
    ebx_icon_button: '<button class="ebx-icon-button"><span class="material-icons-outlined ebx-icon">thumb_up</span></button>',
    ebx_icon_button_no_shadow: '<button class="ebx-icon-button ebx-icon-button__no-shadow"><span class="material-icons-outlined ebx-icon">thumb_up</span></button>',
    ebx_icon_button_dark: '<button class="ebx-icon-button ebx-icon-button__dark"><span class="material-icons-outlined ebx-icon">thumb_up</span></button>',
    ebx_icon_button_disabled: '<button class="ebx-icon-button ebx-icon-button__disabled"><span class="material-icons-outlined ebx-icon">thumb_up</span></button>',
    /**
     * COMPONENTS
     */
    ebx_timeline: `
    <EbxTimeline 
        :navigation="true" 
        :options="options" 
        :maxPointsShown="4" 
        :modelValue="timelineSelection" 
        @update:modelValue="setSelectedOption"/>`,
    progress_indicator: `
        <ProgressIndicator 
            v-for="operation in operations" 
            :key="operation.id"
            :operation="operation" />`,
    ebx_inline_input: `
        <EbxInlineInput 
            :modelValue="titleExample" 
            @update:modelValue="titleExample = $event"
            :id="'title_field'"
            :input-type="'text'"
            :input-style-classes="['ebx-header-2']"
            :edit-actions-position="'right'"
            :disabled="false">
        </EbxInlineInput>`,
    ebx_inline_input_invalid: `
        <EbxInlineInput 
            :modelValue="descriptionExample" 
            @update:modelValue="descriptionExample = $event"
            :id="'description-example'"
            :input-placeholder="'descriptionPlaceholder'" 
            :input-type="'text'"
            :input-style-classes="['ebx-primary-bold']"
            :input-validation="testValidation"
            :edit-actions-position="'bottom-right'"
            :disabled="false">
        </EbxInlineInput>`,
    ebx_inline_input_disabled: `
        <EbxInlineInput 
            :modelValue="descriptionExample" 
            @update:modelValue="descriptionExample = $event"
            :id="'description-example-disabled'"
            :input-placeholder="'descriptionPlaceholder'" 
            :input-type="'text'"
            :input-style-classes="['ebx-primary-bold']"
            :edit-actions-position="'bottom-right'"
            :disabled="true">
        </EbxInlineInput>`,
    ebx_search_bar: '<EbxSearchBar :modelValue="searchBar" @update:modelValue="searchBar = $event" :filter-options="searchBarOptions" />',
    ebx_search_bar_no_filter: '<EbxSearchBar :modelValue="searchBar" @update:modelValue="searchBar = $event" />',
    ebx_search_bar_no_search: '<EbxSearchBar :modelValue="searchBar" @update:modelValue="searchBar = $event" :hide-search="true" :filter-options="searchBarOptions" />',
    ebx_simple_card_blank: `
        <div class="ebx-template-card ebx-template-card--blank">
            <EbxSimpleCard title="Create new blank project"><template v-slot:image><md-icon>add</md-icon></template></EbxSimpleCard>
        </div>`,
    ebx_simple_card_thumbnail: '<div class="ebx-template-card" style="width: 200px"><EbxSimpleCard :imgSrc="assets.icons.datasetImagePlaceholder" :title="Thumbnail card" /></div>',
    ebx_simple_card_actions: '<EbxSimpleCard :imgSrc="assets.icons.datasetImagePlaceholder" :title="Thumbnail card" show-left-action="true" :show-right-action="true" @action-left-click="alert(left action)" @action-right-click="alert(right action)"/>',
    ebx_folder_card: `<EbxPackageCard />`,
    file_drop_zone: `
        <div class="polygon-upload">
            <div class="upload-container">
                <div v-if="filesToUpload.length === 0"
                    id="drop_zone"
                    class="upload-container--drop-zone"
                    @drop="dropHandler($event)"
                    @dragover="dragOverHandler($event)">
                    <p class="md-body-1">Drag and drop a GeoJSON, KML, KMZ, or a zipped shape file</p>
                    <button class="ebx-button ebx-button--secondary" @click="triggerFileSelection()">Choose File</button>
                    <input
                        v-show="false"
                        ref="fileInput"
                        class="ebx-button md-button-secondary md-accent" 
                        type="file" 
                        :accept="acceptedTypes"
                        @change="addFile($event)"/>
                </div>
                <div class="upload-container--file-zone" v-if="filesToUpload.length>0">
                    <span 
                        v-for="file in filesToUpload"
                        :key="file.name"
                        class="md-body-1">
                        {{file.name}}
                    </span>
                    <md-button class="md-icon-button md-primary" @click="removeFile"><md-icon>close</md-icon></md-button>
                </div>
                <EbxAlert theme="error" v-if="uploadError">
                    {{uploadErrorMessage}}
                </EbxAlert>
            </div>
        </div>`,
    /**
     * OTHER
     */
    ebx_progress_bar: `
        <div class="ebx-progress-bar">
            <div class="ebx-progress-bar--container">
                <div class="ebx-progress-bar--progress"></div>
            </div>
        </div>`,
    asset_card: `
        <div class="asset-card--item">
            <div class="asset-card--item-body">
                <div class="asset-card--item-title">
                    <div class="md-body-2">
                        Asset card title
                    </div>
                </div>
                <div class="asset-card--sub-title">
                    <p class="ebx-secondary">asset card sub title</p>
                </div>
            </div>
            <div class="asset-card--item-actions">
                <md-button class="md-icon-button"><md-icon>file_download</md-icon></md-button>
            </div>
        </div>
    `,
    asset_card_icon: `
        <div class="asset-card--item">
            <div class="asset-card--item-body">
                <div class="asset-card--item-title">
                    <div class="ebx-icon">
                        <img :src="assets.icons.folderZip" alt="Folder">
                    </div>
                    <div class="md-body-2">
                        Asset card title
                    </div>
                </div>
            </div>
            <div class="asset-card--item-actions">
                <md-button class="md-icon-button"><md-icon>file_download</md-icon></md-button>
            </div>
        </div>`,
    asset_card_action: `
        <div class="asset-card--item asset-card--item__action">
            <div class="asset-card--item-body">
                <div class="asset-card--item-title">
                    <p class="ebx-primary-bold">Card is clickable</p>
                </div>
                <div class="asset-card--sub-title">
                    <p class="ebx-secondary">description</p>
                </div>
            </div>
            <div class="asset-card--item-actions">
                <v-btn icon="mdi-dots-vertical" variant="text" density="compact"></v-btn>
            </div>
        </div>`,
    asset_card_thumbnail: `<div class="asset-card--item">
    <div class="asset-card--item-body">
        <div class="asset-card--item-title">
            <div class="asset-card--thumbnail">
                <img :src="assets.icons.datasetImagePlaceholder" alt="Thumnail">
            </div>
            <div class="ebx-primary-bold">
                Card with thumbnail
            </div>
        </div>
    </div>
    <div class="asset-card--item-actions">
        <v-btn icon="mdi-dots-vertical" variant="text" density="compact"></v-btn>
    </div>
</div>`,
    asset_card_thumbnail_text: `<div class="asset-card--item">
    <div class="asset-card--item-body">
        <div class="asset-card--item-title">
            <div class="asset-card--thumbnail">
                <img :src="assets.icons.datasetImagePlaceholder" alt="Thumnail">
            </div>
            <div class="asset-card-body-text">
                <div class="asset-card--item-title">
                    <p class="ebx-primary-bold">Card title</p>
                </div>
                <div class="asset-card--sub-title">
                    <p class="ebx-secondary">description</p>
                </div>
            </div>
        </div>
    </div>
    <div class="asset-card--item-actions">
        <v-btn icon="mdi-dots-vertical" variant="text" density="compact"></v-btn>
    </div>
</div>`,
    asset_card_disabled: `<div class="asset-card--item disabled">
    <div class="asset-card--item-body">
        <div class="asset-card--item-title">
            <div class="asset-card--thumbnail">
                <img :src="assets.icons.datasetImagePlaceholder" alt="Thumnail">
            </div>
            <div class="asset-card-body-text">
                <div class="asset-card--item-title">
                    <p class="ebx-primary-bold">Card title</p>
                </div>
                <div class="asset-card--sub-title">
                    <p class="ebx-secondary">description</p>
                </div>
            </div>
        </div>
    </div>
    <div class="asset-card--item-actions">
        <v-btn icon="mdi-dots-vertical" variant="text" density="compact"></v-btn>
    </div>
</div>

<div class="asset-card--item disabled">
    <div class="asset-card--item-body">
        <div class="asset-card--item-title">
            <div class="ebx-icon">
                <img :src="assets.icons.folderZip" alt="Folder">
            </div>
            <div class="md-body-2">
                Asset card with icon
            </div>
        </div>
    </div>
    <div class="asset-card--item-actions">
        <md-button class="md-icon-button"><md-icon>file_download</md-icon></md-button>
    </div>
</div>`,
    /**
     * MODALS
     */
    confimation_modal: `
        <ConfirmationModal ref="confirm" :modelValue="showConfirm" @update:modelValue="showConfirm = $event" title="Wanna confirm stuff?" ok-button-text="Yeah sure" cancel-button-text="Nah" :if-warning="false">
            <p>This is a confirmation modal, do you want to confirm stuff?</p>
        </ConfirmationModal>`,
    confimation_modal_destructive: `
    <ConfirmationModal ref="confirm" :modelValue="showDeleteConfirm" @update:modelValue="showDeleteConfirm = $event" title="Wanna delete stuff?" ok-button-text="Delete it" cancel-button-text="Nah" :if-warning="true">
        <p>This is a confirmation modal, do you want to delete stuff?</p>
    </ConfirmationModal>`,
    ebx_dialog: `
        <EbxDialog :showEbxDialog="showEbxDialog" :cancellable="false" 
            :linkDialog="{
                isLink: true,
                linkText: 'Manage your subscription',
                linkTarget: 'www.google.com',
                linkEmail: '/search?q=earthblox+support'
            }" 
            :dialogContent="{
                title: 'Your trial has ended',
                body: 'To continue using Earth Blox, add your payment details. '
            }" 
            :dialogClasses="['modal-ms']" />`,
    ebx_error_dialog: `
        <EbxErrorDialog
            :showErrorDialog="showErrorDialog"
            :errorDialogMessage="'This is an error message'"
            @error-dialog-active="handleErrorDialogActive"
        />`,
    ebx_button_circle: `<button class="ebx-button ebx-button--circle" style="background-color: red;"></button>`,
    ebx_button_icon: `<button class="ebx-button ebx-button--circle"><md-icon>add</md-icon></button>`,
    ebx_button_active: `<button class="ebx-button ebx-button--circle is-active" style="background-color: lightgrey;"></button>`,
    ebx_tabs:`<EbxTabs>
        <EbxTab id="tab1" title="Tab 1">
            <p>Tab 1 content</p>
        </EbxTab>
        <EbxTab id="tab2" title="Tab 2">
            <p>Tab 2 content</p>
        </EbxTab>
    </EbxTabs>
    `,
    ebx_pills: `
    <div class="ebx-pill">Default Pill</div>
    <div class="ebx-pill ebx-pill--pine">Pine Pill</div>
    <div class="ebx-pill ebx-pill--loch">Loch Pill</div>
    <div class="ebx-pill ebx-pill--gorse">Gorse Pill</div>
    `,
    ebx_switch: `
    <div>
        Animated
    <EbxSwitch on-text="Active" off-text="In-Active" :animate="true" />
    </div>
    <div>
        Not Animated
        <EbxSwitch on-text="Active" off-text="In-Active" :animate="false" />
    </div>
    <div>
        Dark mode
        <EbxSwitch on-text="Active" off-text="In-Active" theme="dark" />
    </div>`,
    ebx_alerts: `
        <EbxAlert theme="success">Success message</EbxAlert>
        <EbxAlert theme="error">Error message</EbxAlert>
        <EbxAlert theme="warning">Warning message</EbxAlert>
        <EbxAlert theme="info">Info message</EbxAlert>`,
    ebx_inline_field: `
    <div class="ebx-inline-label-field">
        <label class="ebx-primary">Select Field</label>
        <md-field>
            <md-select placeholder="Select default start date">   
            </md-select>   
        </md-field>
    </div>
    <div class="ebx-inline-label-field">
        <label class="ebx-primary">Text Field</label>
        <md-field>
            <md-input placeholder="Select default start date" />
        </md-field>
    </div>
    <div class="ebx-inline-label-field disabled">
        <label class="ebx-primary">Text Field Disabled</label>
        <md-field>
            <md-input :disabled="true" placeholder="Select default start date" />
        </md-field>
    </div>`,
    ebx_concertina: `
    <div class="ebx-concertina">
        <div class="ebx-concertina--container">
            <transition name="concertina">
                <div class="ebx-concertina--content" v-if="showConcertina">
                    Content to show or hide
                </div>
            </transition>
            <div class="ebx-concertina--header">
                <div class="ebx-concertina--header--title">
                    <div class="ebx-primary-bold" v-if="!showConcertina">Show columns</div>
                    <div class="ebx-primary-bold" v-else>Hide columns</div>
                </div>
                <div class="ebx-concertina--header--icon">
                    <button 
                    v-if="!showConcertina"
                    class="ebx-icon-button ebx-icon-button__no-shadow"
                    @click="showConcertina = true"><span class="material-icons-outlined ebx-icon">keyboard_arrow_down</span></button>
                    <button 
                    v-else
                    class="ebx-icon-button ebx-icon-button__no-shadow"
                    @click="showConcertina = false"><span class="material-icons-outlined ebx-icon">keyboard_arrow_up</span></button>
                </div>
            </div>
        </div>
    </div>`,

}