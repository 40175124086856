<template>
    <div v-show="false">
        <div ref="extraModes" class="extra-modes">
            <div v-if="showDrawingManager" class="ebx-drawing-controls">
                <div v-if="tooltipState !==null" class="ebx-drawing-controls--tooltip ebx-secondary">
                    <div v-if="tooltipState === 'select' && !hasSelectedEditableOverlay">Select a shape to edit</div>
                    <div v-if="tooltipState === 'select' && hasSelectedEditableOverlay">Shape selected</div>
                    <div v-if="tooltipState === 'deleted'">Shape removed</div>
                    <div v-if="tooltipState === 'rectangle'">Rectangle drawing</div>
                    <div v-if="tooltipState === 'polygon'">Polygon drawing</div>
                </div>
                <div class="ebx-drawing-controls--actions">
                    
                    <button :disabled="isSelectMode" :class="actionButtonClass('select')" @click="enterSelectMode()">
                        <img :src="assets.icons.cursor" />
                    </button>
                    <button :disabled="isRectangleMode" :class="actionButtonClass('rectangle')" @click="setDrawingMode('rectangle')">
                        <img :src="assets.icons.rectangle" />
                    </button>
                    <button :disabled="isPolygonMode" :class="actionButtonClass('polygon')" @click="setDrawingMode('polygon')">
                        <img :src="assets.icons.irregularShape" />
                    </button>
                    <button :disabled="!hasSelectedEditableOverlay" @click="deleteEditableOverlay()">
                        <img :src="assets.icons.deleteOutline" />
                    </button>
                </div>
                <div 
                    class="ebx-drawing-controls--done" 
                    @click="handleDoneOverlays()"
                >
                    <button class="ebx-link ebx-link__light" @click="handleDoneOverlays()">Done</button>
                    <div class="ebx-drawing-controls--done-info ebx-secondary" @click="handleDoneOverlays()">Enter ⮐</div>
                </div>
            </div>
            <slot
                v-bind:polygonClicked="polygonClicked"
                v-bind:addArea="addArea"
                v-bind:setPolygonListeners="setPolygonListeners"
                v-bind:setOverlayListeners="setOverlayListeners"
                v-bind:handleNewMarkerAdded="handleNewMarkerAdded"
            ></slot>
        </div>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { AreaService } from "@/services/area.service";
import PopupMixin from "./CustomPopups/PopupMixin";
import {RightClickPopup} from "./CustomPopups/RightClickPopup";
import ReassignShapeMenu from "@/components/ResultMap/CustomPopups/ReassignShapeMenu.vue";
import {globalEventBus} from "@/eventbus";
import assetsMixin from "@/components/mixins/assetsMixin.js" 
import { markRaw } from "vue";

const getEventType = event => {
    if (
        event.type === "polygon" && 
        event.overlay && 
        typeof event.overlay === 'object' && 
        typeof event.overlay.getPath === 'function' && 
        event.overlay.getPath().getLength() === 1
    ) {
        return "point";
    } 
    return event.type
};

export default {
    mixins: [PopupMixin, assetsMixin],
    props: {
        google: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        selectedArea: {
            type: Object,
            required: false,
            default: null
        },
        showDrawingManager: {
            type: Boolean,
            required: true
        },
        isExplorer: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'disable-run',
        'add-area',
        'done-overlays'
    ],
    data() {
        return {
            drawingModes: ["polygon", "rectangle"],
            dirtyShapes: [],
            previousPosition: this.google.maps.ControlPosition.BOTTOM_CENTER,
            editableOverlay: null,
            tooltipState: null,
            lastSelectedArea: null,
            shapeContextMenu: null,
            windowEventHandler: null
        };
    },
    watch: {
        selectedArea(newArea) {
            if(newArea === null) {
                return
            }
            this.drawingManager.setOptions({
                polygonOptions: {
                    strokeColor: newArea.colour,
                },
                rectangleOptions: {
                    strokeColor: newArea.colour,
                },
            });

            AreaService.setShapesEditable(false);
            AreaService.setShapesClickable(true, newArea.id);
        },
        dirtyShapes: {
            handler: function(dirtyShapes) {
                if(dirtyShapes.length > 0) {
                    this.$emit('disable-run', true);
                } else {
                    this.$emit('disable-run', false);
                } 
            },
            deep: true 
        },
        showDrawingManager(newValue) {
            this.toggleDrawingManager(newValue)
            this.toggleKeyboardCancelEvent(newValue)
        },
        drawingMode(mode) {

            AreaService.setShapesEditable(false);
            AreaService.setShapesClickable(false);

            if(mode === 'select' || mode === 'delete'){
                AreaService.setShapesClickable(true, this.selectedArea?.id);
            } else {
                this.editableOverlay = null; 
            }
            if(mode === 'select' || mode === 'polygon' || mode === 'rectangle') {
                this.tooltipState = mode
            }
            if(mode === null && this.tooltipState !== 'deleted') {
                this.tooltipState = null
            }

            if(this.drawingModes.indexOf(mode) >=0) {
                this.drawingManager.setDrawingMode(mode)
            } else {
                this.drawingManager.setDrawingMode(null)
            }
            
            if(mode === 'polygon' || mode === 'rectangle') {
                this.map.setOptions({ draggableCursor: "crosshair" });
            } else {
                this.map.setOptions({ draggableCursor: "" });
            }
        },
        isExplorer(newValue) {
            // if an explorer has started a drawing but not saved it, remove the area and shapes upon switching to a creator
            if(newValue === false && this.showDrawingManager && this.selectedArea) {
                AreaService.removeArea(this.selectedArea);
            }
            // on toggle of this always reset the drawing mode
            this.$store.commit('maplayers/setDrawingModeEnabled', false)
            this.$store.commit('maplayers/setDrawingModeMode', null)
        }
    },
    methods: {
        actionButtonClass(mode) {
            return {
                'active': this.drawingMode === mode
            }
        },
        toggleDrawingManager(shown) {
            if(shown) {
                this.drawingManager.setMap(this.map);
            } else {
                this.drawingManager.setMap(null);
            }
        },
        toggleKeyboardCancelEvent(shown) {
            if(shown) {
                window.addEventListener('keydown', this.windowEventHandler);
            } else {
                window.removeEventListener('keydown', this.windowEventHandler);
            }
        },
        createDrawingManager() {
            const { DrawingManager } = this.google.maps.drawing;
            
            this.drawingManager = new DrawingManager({
                drawingMode: null,
                drawingControl: false, // always false as we create our own controls
                drawingControlOptions: {
                    position: this.controlPosition,
                    drawingModes: this.drawingModes,
                },
                polygonOptions: {
                    editable: false,
                    fillOpacity: 0.05,
                },
                rectangleOptions: {
                    editable: false,
                    fillOpacity: 0.05,
                    strokeWeight: 3,
                },
                polylineOptions: {
                    editable: true,
                    fillOpacity: 0.05,
                    strokeWeight: 1.5
                },

            });

            this.setListeners();

            const extraModes = this.$refs.extraModes;
            this.map.controls[this.controlPosition].push(extraModes);

            this.previousPosition = this.controlPosition;
        },
        addArea(areaName) {
            this.$emit('add-area', areaName);
        },
        setListeners() {
            let addListener = this.google.maps.event.addListener;


            addListener(this.map, "contextmenu", () => {
                this.setDrawingMode(null);
            });

            addListener(this.drawingManager, "overlaycomplete", (event) => {
                this.onOverlayComplete(event)
                this.setOverlayListeners(event.overlay, getEventType(event));      
            });

            addListener(this.drawingManager, "rectanglecomplete", (rectangle) => {
                this.setRectangleListeners(rectangle);
            });

            addListener(this.drawingManager, "polygoncomplete", (polygon) => {
                this.setPolygonListeners(polygon)
            });
        },
        onOverlayComplete(event) {
            let overlay = event.overlay;
            let selectedArea = this.selectedArea;
            let customAreas = AreaService.getCustomAreas();

            if(customAreas.length === 0 || !selectedArea) {
                this.$emit('add-area'); 
                customAreas = AreaService.getCustomAreas();
                selectedArea = AreaService.getSelectedArea();
            }

            overlay.setOptions({
                fillOpacity: 0,
                strokeWeight: 4,
                strokeColor: selectedArea.colour,
            });

            // setting overlay areaID, this is used for 
            overlay.areaId = selectedArea.id;
            AreaService.addUserDrawnShape(selectedArea.id, overlay);
            this.lastSelectedArea = selectedArea;
            if (this.isExplorer) {
                this.$store.commit('maplayers/setLastCreatedArea', null)
                this.$store.commit('maplayers/setLastCreatedArea', this.lastSelectedArea)
            }
        },
        setOverlayListeners(overlay, type){
            let addListener = this.google.maps.event.addListener;

            addListener(overlay, "click" , (event) => {
                
                this.$store.commit('mapevents/addEvent', {
                    type: "click",
                    event
                })

                if(this.drawingMode === 'select') {
                    this.selectOverlay(overlay)
                    return;
                }

                if(type === 'point') {
                    return this.pointClicked(overlay)
                }
                
                return this.polygonClicked(overlay)
                
            });
            addListener(overlay, "contextmenu", (event) => {
                if(overlay.clickable) {
                    overlay.setEditable(true)
                }
                if(this.isExplorer) {
                    return;
                }
                if(this.isSelectMode) {
                    this.shapeContextMenu.handleRightClick(event).setPopupProps({
                        areas: AreaService.getCustomAreas(),
                        selectedArea: this.selectedArea,
                        clickHandler: (area) => this.assignAreaToOverlay(overlay, area)
                    })
                    return
                }
                if (this.showDrawingManager === false) {
                    this.$store.commit('mapevents/addEvent', {
                        type: "contextmenu",
                        event
                    })
                }
                
            })
            // If mousemove events are needed, uncomment the following lines
            // removed for now due to excessive event logging
            // addListener(overlay, "mousemove", (event) =>
            //     this.$store.commit('mapevents/addEvent', {
            //         type: "mousemove",
            //         event
            //     })
            // );
        },
        selectOverlay(overlay) {
            AreaService.setShapesEditable(false);
            overlay.setEditable(true);
            this.editableOverlay = overlay;
        },
        assignAreaToOverlay(overlay, area) {
            if(area.id === this.selectedArea.id) {
                return;
            }
            overlay.setOptions({
                strokeColor: area.colour,
                clickable: false,
                editable: false
            });
            
            overlay = AreaService.updateAreaIdOfShape(overlay.shapeId, area.id, overlay);
            this.editableOverlay = null;
            this.shapeContextMenu.hide();

        },
        setRectangleListeners(rectangle) {
            let addListener = this.google.maps.event.addListener;
            addListener(rectangle, "bounds_changed", () => {
                AreaService.publishShapeUpdate({
                    areaId: rectangle.areaId,
                    shapeId: rectangle.shapeId,
                });
            });
        },
        setPolygonListeners(polygon) {
            let addListener = this.google.maps.event.addListener;
            let path = polygon.getPath();

            addListener(path, "insert_at", () => {
                AreaService.publishShapeUpdate({
                    areaId: polygon.areaId,
                    shapeId: polygon.shapeId,
                });
            });
            addListener(path, "set_at", () => {
                AreaService.publishShapeUpdate({
                    areaId: polygon.areaId,
                    shapeId: polygon.shapeId,
                });
            });
            addListener(path, "remove_at", () => {
                AreaService.publishShapeUpdate({
                    areaId: polygon.areaId,
                    shapeId: polygon.shapeId,
                });
            });
        },
        pointClicked(point) { 
            if (this.drawingMode === "delete") {
                this.deleteShape(point);
                this.enterSelectMode()
                return;
            }
        },
        polygonClicked(polygon) {
            if (this.drawingMode === "delete") {
                this.deleteShape(polygon);
                this.enterSelectMode()
                return;
            }
        },
        enterSelectMode() {
            this.setDrawingMode("select");
            this.map.setOptions({ draggableCursor: "pointer" });
        },
        deleteEditableOverlay() {
            if (this.editableOverlay) {
                this.deleteShape(this.editableOverlay);
                this.editableOverlay = null;
                this.enterSelectMode();
            }
        },
        deleteShape(shape) {
            let index = this.dirtyShapes.indexOf(shape);
            if(index !== -1) {
                this.dirtyShapes.splice(index, 1);
            }
            AreaService.removeShapeByID(shape.shapeId);
            this.editableOverlay = null;
            this.tooltipState = 'deleted'
            return;
        },
        setDrawingMode(mode) {
            this.drawingMode = mode;
        },
        handleNewPolygonAdded(polygon){
            let addListener = this.google.maps.event.addListener;
            this.setPolygonListeners(polygon)
            this.setOverlayListeners(polygon, 'polygon');
            addListener(polygon, "click", () =>
                this.polygonClicked(polygon)
            );
        },
        handleNewMarkerAdded(marker) {
            this.setOverlayListeners(marker, 'point');
        },
        handleDoneOverlays() {
            this.setDrawingMode(null);
            this.$emit('done-overlays', this.lastSelectedArea)
        }
    },
    mounted() {

        this.createDrawingManager();
        this.toggleDrawingManager(this.showDrawingManager);

        globalEventBus.$on('added-new-polygon', this.handleNewPolygonAdded);
        globalEventBus.$on('added-new-marker', this.handleNewMarkerAdded);

        this.shapeContextMenu = new RightClickPopup(markRaw(ReassignShapeMenu))
        this.registerPopup(this.shapeContextMenu.withoutBindingClickEvents());

        this.windowEventHandler = (event) => {
            if(event.key === 'Enter' || event.key === 'Escape') {
                this.handleDoneOverlays()
            }
            if(event.key === 'Delete' && this.hasSelectedEditableOverlay) {
                this.deleteEditableOverlay()
            }
        }
    },
    beforeUnmount() {
        if(this.clearAreasSubscription) {
            this.clearAreasSubscription.unsubscribe();
        }
        globalEventBus.$off('added-new-polygon', this.handleNewPolygonAdded);
        globalEventBus.$off('added-new-marker', this.handleNewMarkerAdded);
        window.removeEventListener('keydown', this.windowEventHandler);
    },
    computed: {
        orgConfig() {
            return this.$store.state.auth.orgConfig
        },
        controlPosition() {
            return this.google.maps.ControlPosition.BOTTOM_CENTER
        },
        isDrawingMode() {
            return this.drawingModes.indexOf(this.drawingMode) !== -1;
        },
        isSelectMode() {
            return this.drawingMode === 'select'
        },
        isRectangleMode() {
            return this.drawingMode === 'rectangle'
        },
        isPolygonMode() {
            return this.drawingMode === 'polygon'
        },
        hasSelectedEditableOverlay() {
            return this.editableOverlay !== null
        },
        drawingMode: {
            get() {
                return this.$store.state.maplayers.drawingMode.mode
            },
            set(mode) {
                this.$store.commit('maplayers/setDrawingModeMode', mode)
            }
        }
    }
};
</script>