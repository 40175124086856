<template>
    <md-dialog v-model:md-active="showDialog" :md-close-on-esc="closeOnEsc" :md-click-outside-to-close="closeOnClickOutside" @md-closed="dialogClosed" class="ebx-dialog dialog-confirmation">
            <md-dialog-title v-if="hideTitle === false">
                <div class="top-actions--container ebx-header-2">
                    <slot name="title">{{ title }}</slot>
                    <div v-if="hideCloseButton === false" class="close-button">
                        <md-button class="md-primary" @click="handleCloseButton"><md-icon>close</md-icon></md-button>
                    </div>
                </div>
            </md-dialog-title>
            <md-dialog-content class="dialog-content">
                <template v-if="tempText === null">
                    <slot>{{text}}</slot>
                </template>
                <div class="ebx-primary" v-else>
                    {{ tempText }}
                </div>
            </md-dialog-content>
            <md-dialog-actions>
                <slot name="actions">
                    <v-btn 
                        variant="text" 
                        color="primary" 
                        :class="cancelButtonClass"
                        v-if="showCancelButton" 
                        @click.prevent="handleAction('cancel')">
                        {{ cancelButtonText }}
                    </v-btn>
                    <v-btn 
                        variant="flat" 
                        :color="ifWarning ? 'error' : 'primary'"
                        v-if="showOkButton" 
                        @click.prevent="handleAction('confirm')">
                        {{ okButtonText }}
                    </v-btn>
                </slot>
            </md-dialog-actions>
    </md-dialog>
</template>
<script>
import valueMixin from '@/components/mixins/valueMixin';
export default {
    props: {
        hideTitle: {
            type: Boolean,
            default: false
        },
        hideCloseButton: {
            type: Boolean,
            default: false
        },
        closeOnEsc: {
            type: Boolean,
            default: true
        },
        closeOnClickOutside: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: 'Are you sure?'
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel'
        },
        okButtonText: {
            type: String,
            default: 'OK'
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        okButtonClass: {
            type: String,
            default: 'md-raised md-primary'
        },
        cancelButtonClass: {
            type: String,
            default: ''
        },
        showOkButton: {
            type: Boolean,
            default: true
        },
        showCancelButton: {
            type: Boolean,
            default: true
        },
        closeButtonAsAction: {
            type: Boolean,
            default: true
        }, 
        ifWarning: {
            type: Boolean,
            default: true
        }
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'closed',
        'confirm',
        'cancel'
    ],
    data() {
        return {
            text: null,
            resolve: null,
            reject: null,
            showDialog: false,
            tempText: null
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.showDialog = value
            }
        },
        showDialog(value) {
            this.$emit('update:modelValue', value)
        }
    },
    methods: {
        dialogClosed() {
            this.$emit('closed')
        },
        // this is to allow for await/async functionality in parent blocks e.g. await this.$refs.confirmModal.confirm('are you sure?')
        // Will always resolve and return back either true or false depending on what the user clicked
        confirm(text) {
            this.tempText = text || this.text;
            this.showDialog = true
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        handleCloseButton() {
            if(this.closeButtonAsAction) {
                this.handleAction('cancel')
            } else {
                this.showDialog = false
            }
        },
        handleAction(action) {
            switch(action) {
                case 'cancel':
                    this.$emit('cancel');
                    if(typeof this.reject === 'function') {
                        this.resolve(false)
                        this.resolve = null
                        this.reject = null
                        this.tempText = null
                        this.showDialog = false
                    }
                    break;
                case 'confirm':
                    this.$emit('confirm');
                    if(typeof this.resolve === 'function') {
                        this.resolve(true)
                        this.resolve = null
                        this.reject = null
                        this.tempText = null
                        this.showDialog = false
                    }
                    break;    
            }
        }
    }
}

</script>