<template>
    <div class="package">
        <div class="package-page">
            <div class="package-page--content">
                <div class="package-page--header">
                    <div class="dataset-page--back-action ebx-action-text ebx-action-text--primary ebx-action-text__icon" @click="exitView">
                        <span class="material-icons-outlined ebx-icon">chevron_left</span>
                        <p>{{backLinkText}}</p>
                    </div>
                    <!-- more options menu -->
                    <v-menu location="bottom" v-if="packageObj && canEdit">
                        <template v-slot:activator="{ props }">
                            <v-btn icon="mdi-dots-vertical" variant="text" density="compact" v-bind="props" aria-label="Menu"></v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="handleManageTemplates">
                                <v-list-item-title class="text-body-2">Add or change templates</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="handleMakeCopy">
                                <v-list-item-title class="text-body-2">Make a copy</v-list-item-title>
                            </v-list-item >
                            <v-list-item @click="handleCopyLink">
                                <v-list-item-title class="text-body-2">Copy link</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="handleDelete">
                                <v-list-item-title class="text-body-2">Delete</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <div v-show="!loadingTemplates && packageObj" class="packageProjects">
                    <EbxInlineInput 
                        class="mb-3"
                        v-model="packageTitle"
                        :field-being-edited="fieldBeingEdited"
                        :is-title="true"
                        :id="'package-title'"
                        :input-type="`text`"
                        :input-style-classes="['ebx-header-1']"
                        :input-validation="titleValidation"
                        :edit-actions-position="`right`"
                        :disabled="!canEdit"
                        :update-value="true"
                        @editing-ebx-field="updateFieldBeingEdited"
                        @save="updateTitle">
                    </EbxInlineInput>
                    <VEbxTextarea
                        :modelValue="packageDescription"
                        :saving="savingDescription"
                        :disabled="!canEdit"
                        :cancel-button-disabled="savingDescription"
                        :close-on-save="false"
                        :error="descriptionValidation.active"
                        :errorMessages="descriptionValidation.active ? descriptionValidation.error : null"
                        placeholder="Add a description"
                        @change="validateDescription"
                        @update:modelValue="updateDescription" />
                    <EbxTemplateWorkflows 
                        class="my-6"
                        :isSubsetTemplates="true" 
                        v-model="loadingTemplates"
                        :tagsHidden="true" 
                        :packageWorkflowIds="projectsAssignedIds" 
                        :packageTitle="packageTitle"
                        :showSearchBar="false"
                        />
                </div>

                <md-progress-spinner v-if="loadingTemplates"
                    md-mode="indeterminate" 
                    class="static-loading-spinner md-accent assets-tab--loading"
                    :md-diameter="80"
                ></md-progress-spinner>
            </div>
        </div>
        <v-snackbar
            v-model="snackbar.show"
            :timeout="snackbar.timeout"
            >
            <p class="text-center">{{ snackbar.text }}</p>
        </v-snackbar>
        <confirmation-modal ref="modal" :hide-title="true" >
            <p class="ebx-primary">
                Are you sure you want to delete the package <span class="font-weight-bold">{{ packageTitle }}</span>?
            </p>
            <template v-slot:actions>
                <v-btn 
                    variant="text" 
                    color="primary" 
                    @click.prevent="handleDeleteAction('cancel')">
                    Cancel
                </v-btn>
                <v-btn 
                    variant="flat" 
                    color="error"
                    :loading="awaitingDelete"
                    @click.prevent="handleDeleteAction('confirm')">
                    Delete
                </v-btn>
            </template>
        </confirmation-modal>
    </div>
</template>


<script>
import EbxTemplateWorkflows from '@/components/EbxComponents/EbxTemplateWorkflows.vue'
import packageMixin from "@/components/mixins/packageMixin";
import authMixin from "@/components/mixins/authMixin";
import { isNotNullOrUndefined } from '@/helpers/generalHelperFunctions.js'
import EbxInlineInput from '@/components/EbxComponents/EbxInlineInput.vue'
import VEbxTextarea from '@/components/EbxComponents/VEbxTextarea.vue'
import ConfirmationModal from "@/components/ConfirmationModal.vue";

export default {
    name: 'Package',
    components: {
        EbxTemplateWorkflows,
        EbxInlineInput,
        VEbxTextarea,
        ConfirmationModal
    },
    mixins: [packageMixin, authMixin],
    data() {
        return {
            loadingTemplates: true,
            backLinkText:'Explore',
            snackbar: {
                show: false,
                text: '',
                timeout: 3000
            },
            fieldBeingEdited: '',
            titleValidation: {
                error: '',
                active: false
            },
            descriptionPlaceholder: 'Add a description',
            descriptionValidation: {
                error: [],
                active: false
            },
            savingDescription: false,
            awaitingDelete: false,
            editedDescription: null,
            packageTitle: '',
            packageDescription: '',
        }
    },
    async beforeRouteLeave(to,from,next) {
        if(this.hasUnsavedChanges) {
            const message = 'Are you sure you want to leave the page? Changes you have made will not be saved.'
                const confirmedLeave = await this.$confirmModal(message ,{
                    title: 'Unsaved Changes',
                    okButtonText: 'Leave',
                })
                if (confirmedLeave === false) {
                    next(false)
                }
        }
        next()
    },
    
    async mounted() {
        await this.getGlobalPackages();
        await this.getTeamPackages(this.user.orgId);
    },
    beforeUnmount() {
        this.$store.commit('global/setBrowserConfirmUnload', false)
    },
    created() {
        this.loadingTemplates = true;
        if (this.packageObj) {
            this.packageTitle = this.packageObj.title || '';
            this.packageDescription = this.packageObj.description || '';
        }
    },
    computed: {
        packageObj() {
            return this.getPackageById(this.$route.params.packageId);
        },
        packageType() {
            if (!this.packageObj) return null;
            return this.packageObj.type;
        },
        projectsAssignedIds() {
            if (!this.packageObj) return [];
            return this.packageObj.projectsAssigned.projectIds;
        },
        canEdit() {
            if (this.packageType === 'global') {
                return this.isSuperAdmin
            } else if(this.packageType === 'team') {
                return this.isOrgGroupAdmin || this.isSuperAdmin
            } else {
                return false
            }
        },
        hasUnsavedChanges() {
            if (this.editedDescription !== null) { 
                return this.editedDescription !== this.packageDescription
            }
            return false
        }
    },
    methods: {
        exitView() {
            if (this.backLinkText === 'Projects') {
                this.$router.push({name: 'Projects-Workflows'})
            } else if (this.backLinkText === 'Explore') {
                this.$router.push({name: 'Projects-Explore'})
            } else {
                this.$router.go(-1);
            }
        },
        handleManageTemplates() {
            this.setProjectsToAdd(this.projectsAssignedIds)
            this.setPackageToEdit(this.packageObj)
            this.setPackageModalType(this.packageType)
            this.setAddNewPackage(false)
            this.setShowPackageModal(true)
        },
        handleMakeCopy() {
            const copyOfPackage = {
                title: `Copy of ${this.packageObj.title}`,
                description: this.packageObj.description,
                type: this.packageType,
                projectsAssigned: this.packageObj.projectsAssigned
            }
            this.setProjectsToAdd(this.projectsAssignedIds)
            this.setAddNewPackage(true)
            this.setPackageToEdit(copyOfPackage)
            this.setPackageModalType(this.packageType)
            this.setShowPackageModal(true)

        },
        handleCopyLink() {
            // copy the page url to the clipboard
            navigator.clipboard.writeText(window.location.href);
            this.snackbar.text = 'Link copied to clipboard';
            this.snackbar.show = true;
        },
        handleDelete() {
            this.$refs.modal.showDialog = true
        },
        validateTitle(title) {
            // empty space as title not allowed
            title = title.trim()
            // reset validation
            this.titleValidation.error = ''
            this.titleValidation.active = false
            const error = this.validatePackageName(title, this.packageType, this.packageObj?.id || null)
            if (error) {
                this.titleValidation.error = error.message
                this.titleValidation.active = error.exists
            }
        },
        validateDescription(description) {
            this.editedDescription = description
            
            // reset validation
            this.descriptionValidation.error = []
            this.descriptionValidation.active = false
            const error = this.validatePackageDescription(description)
            if (error) {
                this.descriptionValidation.error = [error.message]
                this.descriptionValidation.active = error.exists
            }
        },
        async handleDeleteAction(action) {
            if(action === 'cancel') {
                this.$refs.modal.showDialog = false
            }
            if(action === 'confirm') {
                this.awaitingDelete = true
                await this.deletePackage(this.packageObj.id);
                this.awaitingDelete = false
                this.$router.push({name: 'Projects-Explore'})
            }
        },
        updateFieldBeingEdited(field) {
            this.fieldBeingEdited = field
        },
        async updateTitle(newTitle) {
            newTitle = newTitle.trim()
            if (this.titleValidation.active) {
                return
            }
            try {
                await this.updatePackage(this.packageObj.id, {title: newTitle});
                this.packageObj.title = newTitle
            } catch (error) {
                console.error('Error updating package title', error)
            }

        },
        async updateDescription(newDescription) {
            if (this.descriptionValidation.active) {
                return
            }
            this.savingDescription = true
            try {
                await this.updatePackage(this.packageObj.id, {description: newDescription});
                this.packageDescription = newDescription
                this.savingDescription = false
            } catch (error) {
                console.error('Error updating package description', error)
                this.savingDescription = false
            }
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (isNotNullOrUndefined(to)) { 
                    const pageState = this.$store.state.router.pageState
                    if (pageState && pageState.origin === "Projects") {
                        this.backLinkText = 'Projects'
                    } else { 
                        this.backLinkText = 'Explore'
                    }
                }
            }
        },
        packageObj: {
            deep: true,
            handler(value) {
                this.packageTitle = value?.title || '';
                this.packageDescription = value?.description || '';
            }
        },
        packageTitle: {
            handler(value, oldValue) {
                if (value === oldValue) return
                this.validateTitle(value)
                return value
            },
            immediate: true
        },
        hasUnsavedChanges(value) {
            this.$store.commit('global/setBrowserConfirmUnload', value)
        }
    }
}
</script>
