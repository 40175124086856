import { AuthService } from "@/services/auth.service";
import { ConfigService } from "@/services/config.service";
import { organisationsCollection, usersCollection } from '@/firebase.js'
import { doc, onSnapshot } from "firebase/firestore";

import { CONFIG_TYPE, FEATURES } from "@/config/constants.js";
import api from '@/services/ebx-api.service.js'

const state = {
    user: null,
    orgConfig: null,
    org: null,
    userFirestoreDoc: null,
    snapshots: {},
    userModeOverride: null,
};

const getters = {
    isOrgGroupAdmin: state => {
        return Boolean(
            state.user &&
            state.user.roles &&
            (
                state.user.roles.includes("admin") ||
                state.user.roles.includes("superadmin") ||
                state.user.roles.filter(role => role.startsWith("group:admin:")).length > 0
            )
        );
    },
    isCustomerSupport: state => {
        return Boolean(
            state.user &&
            state.user.roles &&
            state.user.roles.includes("customer_support")
        );
    },
    isSuperAdmin: state => {
        return Boolean(
            state.user &&
            state.user.roles &&
            state.user.roles.includes("superadmin")
        );
    },
    isAdmin: state => {
        return Boolean(
            state.user &&
            state.user.roles &&
            (
                state.user.roles.includes("admin") ||
                state.user.roles.includes("superadmin")
            )
        );
    },
    isCreator: state => {
        return Boolean(
            state.user &&
            state.user.roles &&
            state.user.roles.includes("creator")
        );
    }, 
    isExplorer: state => {
        return Boolean(
            state.user &&
            state.user.roles &&
            state.user.roles.includes("explorer")
        );
    },
    userVariablesEnabled: state => {
        return (state.userFirestoreDoc?.features?.variables) === true;
    },
    // Return an array group group names that the user is an admin of. strip the group:admin: prefix
    userAdminOfGroups: state => {
        return state.user && Array.isArray(state.user.roles) ? state.user.roles.filter(role => role.startsWith("group:admin:")).map(role => role.substring(role.lastIndexOf(":") + 1)) : [];
    },

    isLoggedIn: state => {
        return state.user !== null && state.org !== null && state.orgConfig !== null && state.loggedIn === true
    },
    getOrgId: state => {
        return state.user ? state.user.orgId : undefined;
    },
    getOrgType: state => {
        return state.orgConfig ? state.orgConfig.accessType : undefined;
    },
    getOrgName: state => {
        return state.org ? state.org.name : undefined;
    },
    orgCustomisations: state => {
        return state.orgConfig ? state.orgConfig.getConfig(CONFIG_TYPE.CUSTOMISATION) : undefined;
    },
    orgGetVisibleDatasets: state => {
        return state.orgConfig ? state.orgConfig.getConfig(CONFIG_TYPE.VISIBLE_DATASETS) : [];
    },
    orgGetGlobalPackageVisibility: state => {
        return state.org && state.org.ebxConfig && state.org.ebxConfig[CONFIG_TYPE.GLOBAL_PACKAGES] ? state.org.ebxConfig[CONFIG_TYPE.GLOBAL_PACKAGES] : {
            visible: [],
            hidden: [],
            hide_new_packages: false
        };
    },
    getWorkflowTemplateCollection: () => {
        return 'default'
    },
    enableTermsAndConditions: state => {
        if (state.user == null) {
            return false
        }
        if (state.org == null) {
            return false
        }
        if (state.org.enableTermsAndConditions !== true) {
            return false
        }
        return true
    },
    allowedJsonExport: state => {
        if (state.orgConfig == null) {
            return false
        }
        const conf = state.orgConfig.getConfig(CONFIG_TYPE.FEATURES)
        if (conf && conf[FEATURES.DOWNLOAD_BLOCKLY_JSON] === true) {
            return true
        }
        return false
    }, 
    //Check the subscription type of the organisation the user is in. 
    isCommercialUser: state =>  {
        const subscriptionType = state.org.subscriptionType
        if (subscriptionType === 'non-commercial') {
            return false; 
        } else {
            return true;
        }
    }
}

const mutations = {
    setUser(state, user) {
        state.user = null
        state.user = user
    },
    setUserDoc(state, userDoc) {
        state.userFirestoreDoc = userDoc
    },
    setOrganisation(state, org) {
        state.org = null
        state.org = org
    },
    setOrgConfig(state, orgConfig) {
        state.orgConfig = null
        state.orgConfig = orgConfig
    },
    updateUserTerms(state, agreed) {
        if (state.user) {
            state.user.termsAgreed = agreed
        }
    },
    setSnapshotSubscription(state, { key, unsubscribe }) {
        state.snapshots[key] = unsubscribe
    },
    clearSnapshotSubscription(state, key) {
        if (state.snapshots[key]) {
            state.snapshots[key]()
            delete state.snapshots[key]
        }
    },
    setUserModeOverride(state, userModeOverride) {
        state.userModeOverride = userModeOverride
    }
}

const actions = {
    async signOut({ commit}) {
        await AuthService.signOut();
        commit('setUser', null)
        commit('setUserDoc', null)
        commit('setOrgConfig', null)
        commit('setOrganisation', null)
        commit('clearSnapshotSubscription', 'org')
        commit('clearSnapshotSubscription', 'user')
    },
    async setUser({ commit, state }, user) {

        if (state.user?.uid !== user.uid) {
            commit('clearSnapshotSubscription', 'org')
            commit('clearSnapshotSubscription', 'user')
            if(user.uid !== null) {
                const snapshots = [
                    new Promise((resolve, reject) => {
                        const orgSnapshot = onSnapshot(doc(organisationsCollection, user.orgId), orgDoc => {
                            commit('setOrganisation', orgDoc.data())
                            resolve()
                        }, reject)
                        commit('setSnapshotSubscription', { key: 'org', unsubscribe: orgSnapshot })
                    }),
                    new Promise((resolve, reject) => {
                        const userSnapshot = onSnapshot(doc(usersCollection, user.uid), userDoc => {
                            commit('setUserDoc', userDoc.data())
                            resolve()
                        }, reject)
                        commit('setSnapshotSubscription', { key: 'user', unsubscribe: userSnapshot })
                    })
                ]
                await Promise.all(snapshots)
            }
        }
        commit('setUser', user)
        if(user.orgId !== undefined && user.orgId !== null) {
            const config = await ConfigService.getOrgConfig(user.orgId)
            commit('setOrgConfig', config)
        }
    },
    async updateOrganisationSettings(_, settings) {
        const organisationSettings = await api.organisation.updateSettings(settings)
        // Updates to organisation handled by snapshot above
        return organisationSettings
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};