<template>
    <div class="assets-container">
        <h1 class="ebx-header-1 mb-3">
            Assets
        </h1>
        <div v-if="isExplorer" id="tab-project-assets-features">
            <router-view></router-view>
        </div>
        <md-tabs v-else md-sync-route>
            <md-tab id="tab-project-assets-features" md-label="Features" to="/projects/assets" exact>
                <router-view></router-view>
            </md-tab>
            <md-tab id="tab-project-assets-images" md-label="Images" to="/projects/assets/images" exact>
                <router-view></router-view>
            </md-tab>
        </md-tabs>
    </div>
</template>

<script>
import authMixin from '@/components/mixins/authMixin';
import userDatasetsMixin from '@/components/mixins/userDatasetsMixin';
export default {
    mixins: [authMixin, userDatasetsMixin],
    mounted() {
        // Explorers and Creators need to load the datasets to load Features & Images
        this.userDatasetsLoad(this.user.orgId, this.user.uid)
    }
}
</script>