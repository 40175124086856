<template>
    <div class="project-header" :class="{'saving': saveProjectSaving, 'saved': projectStateWithEditingName === 'saved'}">
        <div class="project-header--title">
            <input v-if="editingName && isCreator" ref="editInput" v-model="headerProjectName" class="project-header--edit-name" />
            <template v-else>
                {{ headerProjectName }}
            </template>
        </div>
        <div v-if="showSaveButtons && isCreator" class="project-header--buttons">
            <SaveProjectButton :disabled="false && !editingName" :state="projectStateWithEditingName" :isReadOnly="projectIsReadOnly" :saving="saveProjectSaving" @click="handleSave" />
            <v-menu v-if="!projectIsReadOnly">
                <template v-slot:activator="{ props }">
                    <button class="ebx-button project-header--expand" :disabled="saveProjectSaving" v-bind="props">
                        <v-icon>mdi-chevron-down</v-icon>
                    </button>
                </template>
                <v-list>
                    <template v-if="editingName">
                        <v-list-item 
                            @click="cancelEditing">
                            <v-list-item-title class="text-body-2">Cancel</v-list-item-title>
                        </v-list-item>
                    </template>
                    <template v-else>
                        <v-list-item 
                            v-if="isSuperAdmin && projectIsGlobalTemplate" 
                            @click="moveToPrivate">
                            <v-list-item-title class="text-body-2">Move Template to Private</v-list-item-title>
                            </v-list-item>
                        <v-list-item
                            v-if="projectIsEditable" 
                            @click="editingName = true">
                            <v-list-item-title class="text-body-2">Rename</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="canShareWorkflows && !projectIsGlobalTemplate && !projectIsPrivate && !projectIsOrgTemplate" 
                            @click="updatePermissions">
                                <v-list-item-title class="text-body-2">Permissions</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="projectIsEditable" 
                            @click="editWorkflow">
                            <v-list-item-title class="text-body-2">Edit name and description</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="(projectIsGlobalTemplate || projectIsOrgTemplate) && (isOrgGroupAdmin || isSuperAdmin)" 
                            @click="showAddToPackageModal = true">
                            <v-list-item-title class="text-body-2">Add to package</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="duplicateWorkflow">
                            <v-list-item-title class="text-body-2">Make a copy</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="canShareWorkflows && !projectIsGlobalTemplate && projectIsPrivate" 
                            @click="moveToTeam">
                            <v-list-item-title class="text-body-2">Move to team</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="projectIsEditable && canShareWorkflows && !projectIsOrgTemplate" 
                            @click="moveToPublished">
                            <v-list-item-title class="text-body-2">Publish as team template</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="canShareWorkflows && projectIsOrgTemplate && (isAdmin || isOwnerOfProject)" 
                            @click="moveToUnPublished">
                            <v-list-item-title class="text-body-2">Unpublish as team template</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="isSuperAdmin && projectIsGlobalTemplate === false" 
                            @click="moveToTemplates">
                            <v-list-item-title class="text-body-2">Publish as Earth Blox Template</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="allowJSONDownload" 
                            @click="handleDownloadWorkflow">
                            <v-list-item-title class="text-body-2">Download workflow JSON</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="(isAdmin || isOwnerOfProject) && !projectIsPrivate && !projectIsOrgTemplate" 
                            @click="handleAPIAccess">
                            <v-list-item-title class="text-body-2">API access</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="projectIsEditable" 
                            @click="deleteWorkflow">
                            <v-list-item-title class="text-body-2">Delete</v-list-item-title>
                        </v-list-item>
                    </template>
                </v-list>
            </v-menu>
        </div>  
        <confirmation-modal ref="modal" :hide-title="confirmTitle === null" :title="confirmTitle" :ok-button-text="okButtonText" :if-warning="desctructiveAction" ok-button-class="md-raised md-success">
            <p class="ebx-primary">
                {{confirmMessage}}
            </p>
            <MoveAssetAlert :assets="assetsRequiringToMove" :mode="publishMode" />
        </confirmation-modal>
        <ConfirmationModal 
            ref="confirm" 
            :title="projectIsGlobalTemplate || projectIsOrgTemplate ? 'Update template?': 'Are you sure?'" 
            ok-button-text="Make a copy" 
            :cancel-button-text="projectIsGlobalTemplate || projectIsOrgTemplate ? 'Update template' : 'Overwrite'" 
            :if-warning="false"
            :close-button-as-action="false"
        >
            <template v-if="isSuperAdmin && projectIsGlobalTemplate">
                <p class="ebx-primary">This project is a template. Do you want to overwrite the original or make a copy?</p>
            </template>
            <template v-else-if="projectIsOrgTemplate">
                <p class="ebx-primary">Saving changes in this project will update the template workflow.
                    Are you sure you want to make changes to this template? </p>
            </template>
            <template v-else>
                <p class="ebx-primary">This project was created by someone else in your organisation. Do you want to overwrite the original or
                make a copy?</p>
            </template>
            <MoveAssetAlert :assets="assetsRequiringToMove" :mode="publishMode" />
        </ConfirmationModal>
        <EbxErrorDialog
                :showErrorDialog="showErrorDialog"
                :errorDialogMessage="errorDialogMessage"
                @error-dialog-active="handleErrorDialogActive"
            />
        <PublishProjectModal 
            v-if="projectIsLoaded" 
            v-model="showPublishModal" 
            :defaults="loadedProjectWithChanges()" 
            :assets="assetsRequiringToMove"
            :mode="publishMode"
            :has-changes="hasProjectChanged()"
            @publish="handleMoveToPublished"
        />
        <AddToPackageModal 
            v-model="showAddToPackageModal" 
            @add="addToPackage"
            :packagesList="this.teamPackages"
        />
    </div>
</template>

<script>
import { sharedWorkflowMixin, saveWorkflowMixin, PROJECT_MODE, PROJECT_STATE } from '@/components/mixins/projectMixin'
import authMixin from '@/components/mixins/authMixin'
import SaveProjectButton from '@/components/ProjectSaveLoad/SaveProjectButton.vue'
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import { BLOCKLY_JSON_EXPORT_IGNORE_KEYS } from '@/constants/nextGenConstants'
import EbxErrorDialog from '@/components/EbxComponents/EbxErrorDialog.vue';
import PublishProjectModal from './PublishProjectModal.vue';
import MoveAssetAlert from './MoveAssetAlert.vue';
import AddToPackageModal from '@/components/PackageCreation/AddToPackageModal.vue';
import packageMixin from '../mixins/packageMixin';

export default {
    mixins: [sharedWorkflowMixin, authMixin, saveWorkflowMixin, packageMixin],
    components:{
        SaveProjectButton,
        ConfirmationModal,
        EbxErrorDialog,
        PublishProjectModal,
        MoveAssetAlert,
        AddToPackageModal
    },
    data() {
        return {
            editingName: false,
            wantedProjectName: null,
            confirmMessage: '',
            desctructiveAction: true,
            confirmTitle: null,
            okButtonText: 'Ok',
            okButtonClass: 'md-raised md-success',
            showModal: false,
            showErrorDialog: false,
            errorDialogMessage: 'Error',
            showPublishModal: false,
            publishMode: null,
            assetsRequiringToMove: [],
            showAddToPackageModal: false
        }
    },
    computed: {
        headerProjectName: {
            get() {
                if(this.wantedProjectName !== null) {
                    return this.wantedProjectName
                }
                return this.wantedProjectName || this.projectName || 'Untitled Project'
            },
            set(value) {
                this.wantedProjectName = value
            }
        },
        showSaveButtons() {
            if(this.projectIsLoaded) {
                return true
            }
            return true
        },
        allowJSONDownload() {
            return this.$store.getters['auth/allowedJsonExport']
        },
        projectStateWithEditingName() {
            if(this.editingName) {
                return PROJECT_STATE.CHANGED
            }
            return this.projectState    
        }
    },
    async mounted() {
        await this.getTeamPackages(this.user.orgId)
        if(this.userVariablesEnabled){
            await Promise.all([this.getGlobalProjects(),this.getTeamProjects(this.user.orgId)]);
        }
    },
    watch: {
        projectId() {
            this.editingName = false
            this.wantedProjectName = null
        },
        editingName(editing) {
            if(editing) {
                this.$nextTick(() => {
                    this.$refs.editInput.focus()
                })
            }
        }
    },
    methods: {
        handleErrorDialogActive(value) {
            this.showErrorDialog = value;
        },
        handleAPIAccess() {
            // open api access modal
            this.$APIConfiguration('',{
                showModal: true,
                projectId: this.projectWorkflow.id,
                project: this.projectWorkflow,
            })
        },
        cancelEditing() {
            this.editingName = false
            this.wantedProjectName = null
        },
        async handleSave() {
            if(this.editingName) {
                this.saveProjectName()
            } else if (this.projectIsReadOnly) { 
                this.duplicateWorkflow()
            } else {
                const loadedProject = this.loadedProjectWithChanges()
                this.assetsRequiringToMove = this.projectAssetNeedingToBeMoved(loadedProject)
                this.publishMode = 'updating' // just needed to alter text in the MoveAssetAlert component
                this.setProjectDefaults(loadedProject)
                this.openProjectSaveModal( this.assetsRequiringToMove)
            }
        },
        duplicateWorkflow() {
            this.onDuplicateWorkflow(this.loadedProjectWithChanges())
        },
        editWorkflow() {
            this.onEditWorkflow(this.projectWorkflow)
        },
        async saveProjectName() {
            if(this.wantedProjectName !== null && this.wantedProjectName.length > 0) {
                let nameUnique = await this.isProjectNameUnique(this.wantedProjectName, this.projectId)
                if (nameUnique === false) {
                    this.showErrorDialog = true;
                    this.errorDialogMessage = 'A project with this name already exists. Please choose a different name.'
                } else {
                    this.saveProjectProjectState = PROJECT_STATE.DETAIL_UPDATE
                    this.saveProjectSave({projectName: this.wantedProjectName}, PROJECT_STATE.DETAIL_UPDATE, true)
                    this.editingName = false
                }
            }
            this.editingName = false
            this.wantedProjectName = null
        },
        async deleteWorkflow() {
            this.confirmMessage = 'Are you sure you want to delete this project?'
            this.okButtonText = 'Delete'
            this.okButtonClass = 'md-raised md-danger'
            this.assetsRequiringToMove = []
            const confirmed = await this.$refs.modal.confirm()
            if (confirmed === false) {
                return
            }
            this.onDeleteWorkflow(this.projectWorkflow)
            this.$router.push( { name:'Projects-Workflows'})
        },
        async moveToTemplates() {
            this.assetsRequiringToMove = this.projectAssetNeedingToBeMoved(this.loadedProjectWithChanges())
            this.showPublishModal = true
            this.publishMode = PROJECT_MODE.GLOBAL_TEMPLATE
        },
        async moveToPublished() {
            this.assetsRequiringToMove = this.projectAssetNeedingToBeMoved(this.loadedProjectWithChanges())
            this.showPublishModal = true
            this.publishMode = PROJECT_MODE.ORG_TEMPLATE  
        },
        async handleMoveToPublished() {
            let nameUnique = await this.isProjectUniqueInPublished(this.projectName, this.projectId)
            if(nameUnique === false) {
                this.showErrorDialog = true;
                this.errorDialogMessage = 'A project with this name already exists as a published template. Please rename before moving.'
                return
            }
            this.setProjectDefaults(this.loadedProjectWithChanges()) // use this.projectWorkflow if you want to use the original project (no workflow changes)
            this.saveProjectSave({
                projectName: this.projectName,
                projectDescription: this.projectDescription,
                projectThumbnail: this.projectThumbnail,
                projectMode: this.publishMode,
                // any extra changes here from the first param of the method will contain the change
            }, PROJECT_STATE.CHANGED, true)
        },
        async moveToUnPublished() {
            this.confirmMessage = 'Unpublishing this template will remove it from your team templates. It will still be available in your Projects.'
            this.okButtonText = 'Unpublish'
            this.desctructiveAction = false
            this.confirmTitle = 'Unpublish template'
            this.assetsRequiringToMove = []
            const confirmed = await this.$refs.modal.confirm()
            if (confirmed === false) {
                return
            }
            this.setProjectDefaults(this.loadedProjectWithChanges()) // use this.projectWorkflow if you want to use the original project (no workflow changes)
            this.saveProjectSave({
                projectName: this.projectName,
                projectDescription: this.projectDescription,
                projectThumbnail: this.projectThumbnail,
                projectMode: this.projectProvenance === 'team' ? PROJECT_MODE.READONLY : PROJECT_MODE.PRIVATE
            }, PROJECT_STATE.CHANGED, true)
        },
        async moveToTeam() {
            let nameUnique = await this.isProjectUniqueInTeam(this.projectName, this.projectId)
            if(nameUnique === false) {
                    this.showErrorDialog = true;
                    this.errorDialogMessage = 'A project with this name already exists in your team. Please rename before moving.'
            } else { 

            this.assetsRequiringToMove = this.projectAssetNeedingToBeMoved(this.loadedProjectWithChanges())
            this.confirmMessage = 'Are you sure you want to move the project to the team?'
            this.okButtonText = 'Share'
            this.desctructiveAction = false;
            this.okButtonClass = 'md-raised md-success'
            this.confirmTitle = null
            this.publishMode = PROJECT_MODE.READONLY
            const confirmed = await this.$refs.modal.confirm()
            if (confirmed === false) {
                return
            }
            this.setProjectDefaults(this.loadedProjectWithChanges()) // use this.projectWorkflow if you want to use the original project (no workflow changes)
            this.saveProjectSave({
                projectName: this.projectName,
                projectDescription: this.projectDescription,
                projectThumbnail: this.projectThumbnail,
                projectMode: PROJECT_MODE.READONLY
            }, PROJECT_STATE.CHANGED, true)

            }
        },
        async moveToPrivate() {
            this.confirmMessage = 'Are you sure you want to move the project back to private?'
            this.okButtonText = 'Unshare'
            this.okButtonClass = 'md-raised md-success'
            this.confirmTitle = null
            this.assetsRequiringToMove = []
            const confirmed = await this.$refs.modal.confirm()
            if (confirmed === false) {
                return
            }
            this.setProjectDefaults(this.loadedProjectWithChanges()) // use this.projectWorkflow if you want to use the original project (no workflow changes)
            this.saveProjectSave({
                projectName: this.projectName,
                projectDescription: this.projectDescription,
                projectThumbnail: this.projectThumbnail,
                projectMode: PROJECT_MODE.PRIVATE
            }, PROJECT_STATE.CHANGED, true)
        },
        updatePermissions() {
            this.onEditWorkflow(this.loadedProjectWithChanges(), PROJECT_STATE.PERMISSION_UPDATE) // use this.projectWorkflow if you want to use the original project (no workflow changes)
        },
        handleDownloadWorkflow() {
            if (this.allowJSONDownload === false) {
                return
            }
            const ignoreKeys = BLOCKLY_JSON_EXPORT_IGNORE_KEYS
            const projectData = this.getProjectAsSerialisedObject(ignoreKeys)
            projectData.blockDefinition = JSON.parse(projectData.blockDefinition)
            projectData.userAreas = JSON.parse(projectData.userAreas)
            const blob = new Blob([JSON.stringify(projectData)], {type: "application/json;charset=utf-8"});
            // Download blob data
            const fileName = `${this.projectName}.json`
            const blobUrl = URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement("a");

            // Set link's href to point to the Blob URL
            link.href = blobUrl;
            link.download = fileName;

            // Append link to the body
            document.body.appendChild(link);

            // Dispatch click event on the link
            // This is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent('click', { 
                bubbles: true, 
                cancelable: true, 
                view: window 
                })
            );

            // Remove link from body
            document.body.removeChild(link);
        },
        async addToPackage(pkg) {
            // Check if project is already in package
            const index = pkg.projectsAssigned.projectIds.indexOf(this.projectWorkflow.id)
            if (index !== -1) {
                this.showAddToPackageModal = false
                return
            }

            const projectIds = [...pkg.projectsAssigned.projectIds, this.projectWorkflow.id]
            let projects = []

            for (const id of projectIds) {
                const template = this.getTemplateById(id)

                if (template.mode === PROJECT_MODE.GLOBAL_TEMPLATE) {
                    projects.push({ref: `shared/default/workflows/${template.id}`})
                } else if (template.mode === PROJECT_MODE.ORG_TEMPLATE) {
                    projects.push({ref: `organisations/${this.user.orgId}/published_workflows/${template.id}`})
                }
            }

            await this.updatePackage(pkg.id, {
                projects_assigned: projects
            })
            this.showAddToPackageModal = false
        },
    }
}
</script>