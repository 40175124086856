<template>
    <md-dialog v-model:md-active="showDialog" :class="modalClass">
        <md-dialog-title>
            <div class="top-actions--container ebx-header-2">
                {{ titleForModal }}
                <div class="close-button">
                    <md-button class="md-primary" @click.prevent="showDialog = false"><md-icon>close</md-icon></md-button>
                </div>
            </div>
        </md-dialog-title>
        <md-dialog-content>
            <p v-if="isGlobalTemplate" class="ebx-primary">
                Publish this project as an Earth Blox template to make it available to all Earth Blox customers. 
            </p>
            <p v-else class="ebx-primary">
                Publish your project as a template to make it available to your whole team.
            </p>
            <MoveAssetAlert :mode="mode" :assets="assets" />
        </md-dialog-content>
        <md-dialog-actions>
            <EbxButton theme="secondary" @click="showDialog = false">Cancel</EbxButton>
            <EbxButton theme="primary" @click="saveProject">
                <template v-if="hasChanges">Save and publish</template>
                <template v-else>Publish</template>
            </EbxButton>
        </md-dialog-actions>
    </md-dialog>
</template>


<script>
import { PROJECT_MODE } from '@/components/mixins/projectMixin'
import MoveAssetAlert from './MoveAssetAlert.vue'
import valueMixin from '@/components/mixins/valueMixin'
export default {
    components: {
        MoveAssetAlert
    },
    props: {
        title: {
            type: String,
            default: null
        },
        modelValue: {
            type: Boolean,
            required: true
        },
        defaults: {
            type: Object,
            required: false,
            default: () => {
                return {}
            }
        },
        hasChanges: {
            type: Boolean,
            required: false,
            default: false
        },
        mode: {
            type: String,
            required: false,
            default: PROJECT_MODE.ORG_TEMPLATE
        },
        //assets that need to be moved
        assets: {
            type: Array,
            required: false,
            default: () => {
                return []
            }
        }
    },
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'publish'
    ],
    computed: {
        modalClass() {
            return {
                'ebx-dialog': true,
                'modal-m': true
            }
        },
        showDialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        isGlobalTemplate() {
            return this.mode === PROJECT_MODE.GLOBAL_TEMPLATE
        },
        titleForModal() {
            if(this.title !== null) {
                return this.title
            }
            if(this.isGlobalTemplate) {
                return 'Publish as Earth Blox template'
            }
            return 'Publish as team template'
        }
    },
    methods: {
        saveProject() {
            this.showDialog = false
            this.$emit('publish', this.defaults)
        }
    }
}
</script>