import {getAppCheckToken, appCheck} from '@/firebase.js'
import {getUserAuthToken} from '@/firebaseFunctions.js'
/*

Example Usage:

import api from '@/services/ebx-api.service.js'
const projects = await api.projects.list()

*/

class OrganisationApiService {
    constructor(ebxApiService) {
        this.ebxApiService = ebxApiService
    }
    // Update a package by ID
    updateSettings(data) {
        return this.ebxApiService.request('PUT', '/organisation/settings' , data)
    }
}
class PackageApiService {
    constructor(ebxApiService) {
        this.ebxApiService = ebxApiService
    }
    // Create a new package
    create(data) {
        return this.ebxApiService.request('POST', '/packages/', data)
    }
    // Update a package by ID
    update(id, data) {
        return this.ebxApiService.request('PUT', '/packages/' + encodeURIComponent(id) , data)
    }
    // Delete a package by ID
    delete(id) {
        return this.ebxApiService.request('DELETE', '/packages/' + encodeURIComponent(id) , {})
    }
}

/**
 * ProjectApiService
 * Public class for managing API requests to the projects endpoint
 */
class ProjectApiService {
    constructor(ebxApiService) {
        this.ebxApiService = ebxApiService
    }
    // Get the list of projects
    list() {
        return this.ebxApiService.request('GET', '/projects/', {})
    }
    // Get a single project by ID
    get(id) {
        return this.ebxApiService.request('GET', '/projects/' + encodeURIComponent(id) , {})
    }
}

/**
 * EbxApiService
 * Private class for managing API requests and auth tokens
 * Also manages the refresh of tokens and App Check Tokens
 */
class EbxApiService {
    constructor(base_url, prefix, token_url) {
        this.base_url = base_url || import.meta.env.VITE_API_BASE_URL;
        this.prefix = prefix || import.meta.env.VITE_API_PREFIX;
        this.token_url = token_url || import.meta.env.VITE_API_TOKEN_URL;
        this.api_token = null
        this.refreshing_api_token = false
        this.pending_requests = []
    }

    // Removes the API token
    removeToken() {
        this.api_token = null
        return this
    }

    // Private methods managing the API token and request queuing
    _getAppCheckToken() {
        return getAppCheckToken(appCheck);
    }
    async _getApiToken(appCheckToken) {
        const headers = {}

        headers["Content-Type"] ='application/json';
        headers["Authorization"] = "Bearer " + ( await getUserAuthToken(true));
        headers["X-Firebase-AppCheck"] = appCheckToken

        const response = await fetch(this.token_url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({"grant_type":"client_credentials"})
        })
        
        return response.json();
    }
    request(method, endpoint, data, attempt = 0) {
        return new Promise((resolve, reject) => {
            this.pending_requests.push({method, endpoint, data, resolve, reject, attempt})
            this._process_requests()
        })
    }
    async _process_requests() {
        if (this._auth_token_expired() && this.refreshing_api_token === false) {
            this.refreshing_api_token = true
            try {
                const appCheckToken = (await this._getAppCheckToken()).token
                const apiToken = await this._getApiToken(appCheckToken)
                this.api_token = {
                    token: apiToken.access_token,
                    expires: Date.parse(apiToken.expires_in)
                }
            } finally {
                this.refreshing_api_token = false
            }
        }
        if (this.refreshing_api_token === false) {
            this.pending_requests.forEach((request) => {
                this._make_request(request)
            })
            this.pending_requests = []
        }
        return this
    }
    _auth_token_expired() {
        if (this.api_token !== null) {
            if (Date.now() < this.api_token.expires) {
                return false
            }
        } 
        return true  
    }
    _build_query_string(data) {
        return Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&')
    }
    async _make_request(request) {
        const headers = {}
        headers["Content-Type"] ='application/json';
        headers["Authorization"] = "Bearer " + this.api_token.token;
        try {
            const requestData = {
                method: request.method,
                headers: headers
            }
            if (request.method !== 'GET') {
                requestData.body = JSON.stringify(request.data)
            }
            let request_url = this.base_url + this.prefix + request.endpoint
            if (request.method === 'GET' && Object.keys(request.data).length > 0){
                request_url += '?' + this._build_query_string(request.data)
            }
            const response = await fetch(request_url, requestData)
            if (!response.ok) {
                if (response.status === 401 && request.attempt < 1) {
                    request.attempt++
                    this.api_token = null
                    this.pending_requests.push(request)
                    this._process_requests()
                } else {
                    request.reject(response)
                }
                return
            }
            const data = await response.json()
            request.resolve(data)
        } catch (error) {
            request.reject(error)
        }
    }
}

const ebxApiService = new EbxApiService();
const projects = new ProjectApiService(ebxApiService);
const packages = new PackageApiService(ebxApiService);
const organisation = new OrganisationApiService(ebxApiService);

/*
Example Usage
import api from '@/services/ebx-api.service.js'
const projects = await api.projects.list()
*/

export default {
    api: ebxApiService,
    projects,
    packages,
    organisation
}