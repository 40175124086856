<template>
    <div class="v-ebx-inline-textarea rounded pa-2" :class="containerClasses">
        <div>
            <v-textarea 
                v-if="isEditing"
                :rows="rows" 
                :placeholder="placeholder" 
                :auto-grow="autoGrow" 
                :disabled="disabled" 
                :readonly="readOnly"
                :error-messages="errorMessages"
                :error="error"
                :hide-details="hideDetails"
                :no-resize="noResize"
                :label="label"
                :id="id"
                :name="name"
                :max-rows="maxRows"
                :rules="rules"
                :validate-on="validateOn"
                :counter="counter"
                :autofocus="autofocus"
                :width="width"
                :max-errors="maxErrors"
                :loading="loading"
                :persistent-counter="persistentCounter"
                :theme="theme"
                :variant="variant"
                :active="active"
                v-model="tempValue" 
                @focus="handleFocus" 
                @blur="handleBlur"
            >
            <template v-for="(_, slotName) in $slots" v-slot:[slotName]="slotProps">
                <slot :name="slotName" v-bind="slotProps ?? {}" />
            </template>
  </v-textarea>
            <div v-else @click="handleFocus" class="v-ebx-inline-textarea__display text-body-2">
                <span v-if="!modelValue || modelValue === ''" class="v-ebx-inline-textarea__placeholder">{{placeholder}}</span>
                <span v-else>
                    <slot name="display" v-bind="{valueLines, modelValue, placeholder}">
                        <div v-for="(line, index) in valueLines" :key="index" class="v-ebx-inline-textarea__line">
                            {{line}}
                        </div>
                    </slot>
                </span>
            </div>
        </div>
        <div v-if="isEditing" class="pt-2">
            <v-btn :color="saveButtonColor" :variant="saveButtonVariant" :disabled="saveButtonDisabled || !hasChanged || error || saving || saveButtonDisabledEmpty" @click="handleSave">
                <slot name="save-button" v-bind="{saving, saveButtonText}">
                    <template v-if="saving">
                        <v-progress-circular indeterminate size="20" color="white"></v-progress-circular>
                    </template>
                    <template v-else>
                        {{saveButtonText}}
                    </template>
                </slot>
            </v-btn>
            <v-btn :color="cancelButtonColor" :variant="cancelButtonVariant" :disabled="cancelButtonDisabled" class="ml-1" @click="handleCancel">
                <slot name="cancel-button" v-bind="{saving, cancelButtonText}">
                    {{cancelButtonText}}
                </slot>
            </v-btn>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        modelValue: {
            type: String,
            required: true
        },
        rows: {
            type: Number,
            required: false,
            default: 3
        },
        placeholder: {
            type: String,
            required: false,
            default: undefined
        },
        autoGrow: {
            type: Boolean,
            required: false,
            default: true
        },
        maxRows: {
            type: Number,
            required: false,
            default: undefined
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        saveButtonText: {
            type: String,
            required: false,
            default: 'Save'
        },
        cancelButtonText: {
            type: String,
            required: false,
            default: 'Cancel'
        },
        saveButtonColor:{
            type: String,
            required: false,
            default: 'primary'
        },
        cancelButtonColor:{
            type: String,
            required: false,
            default: 'primary'
        },
        saveButtonVariant: {
            type: String,
            required: false,
            default: 'flat'
        },
        cancelButtonVariant: {
            type: String,
            required: false,
            default: 'text'
        },
        errorMessages: {
            type: Array,
            required: false,
            default: () => []
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        display:{
            type: String,
            required: false,
            default: 'inline'
        },
        cancelOnBlur:{
            type: Boolean,
            required: false,
            default: false
        },
        saveButtonDisabled: {
            type: Boolean,
            required: false,
            default: false
        }, 
        cancelButtonDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        saving: {
            type: Boolean,
            required: false,
            default: false
        },
        disableSaveOnEmpty: {
            type: Boolean,
            required: false,
            default: false
        },
        closeOnSave: {
            type: Boolean,
            required: false,
            default: true
        },
        openByDefault: {
            type: Boolean,
            required: false,
            default: false
        },
        noResize: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: undefined
        },
        id: {
            type: String,
            required: false,
            default: undefined
        },
        name: {
            type: String,
            required: false,
            default: undefined
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        validateOn:{
            type: String,
            required: false,
            default: undefined
        },
        counter:{
            type: [String, Number, Boolean],
            required: false,
            default: () => undefined
        },
        autofocus: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
            type: [String, Number],
            required: false,
            default: () => undefined
        },
        maxErrors: {
            type: Number,
            required: false,
            default: 1
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        persistentCounter:{
            type: Boolean,
            required: false,
            default: false
        },
        theme: {
            type: String,
            required: false,
            default: undefined
        },
        variant: {
            type: String,
            required: false,
            default: undefined
        },
        active: { // setting this to false when combined with label will animate the label
            type: Boolean,
            required: false,
            default: true
        }
    },
    emits: [
        'update:modelValue',
        'cancel',
        'change',
        'opened'
    ],
    data() {
        return {
            isEditing: false,
            tempValue: null,
        }
    },
    computed: {
        readOnly() {
            return this.disabled || !this.isEditing || this.saving || this.loading;
        },
        containerClasses() {
            return {
                'v-ebx-inline-textarea--disabled': this.disabled,
                'v-ebx-inline-textarea--editing': this.isEditing,
                'v-ebx-inline-textarea--error': this.error,
                'v-ebx-inline-textarea--display-block': this.display === 'block'
            }
        },
        valueLines() {
            return this.modelValue.split('\n');
        },
        hasChanged() {
            return this.tempValue !== this.modelValue;
        },
        saveButtonDisabledEmpty() {
            return this.disableSaveOnEmpty && (!this.tempValue || this.tempValue.trim() === '');
        },
        hideDetails() {
            if (this.error === true) {
                return false;
            }
            if (this.counter !== undefined) {
                return false;
            }
            return true
        }
    },
    watch: {
        modelValue:{
            handler(val) {
                this.tempValue = val;
            },
            immediate: true
        },
        disabled: {
            handler(val) {
                if(val) {
                    this.isEditing = false;
                }
            }
        },
        saving: {
            handler(val, prev) {
                if(prev === true && val === false && this.closeOnSave === false) {
                    this.isEditing = false;
                }
            }
        },
        openByDefault: {
            handler(val) {
                if(val) {
                    this.isEditing = true;
                }
            },
            immediate: true
        },
        tempValue: {
            handler(val) {
                this.$emit('change', val);
            }
        }
    },
    methods: {
        handleFocus() {
            if(this.disabled) {
                return;
            }
            this.$emit('opened');
            this.isEditing = true;
        },
        handleBlur() {
            if (this.saving) {
                return;
            }
            if (!this.hasChanged && this.cancelOnBlur) {
                this.isEditing = false;
            }
        },
        handleSave() {
            if (this.saving || this.hasChanged === false) {
                return;
            }
            this.$emit('update:modelValue', this.tempValue);
            if(this.closeOnSave) {
                this.isEditing = false;
            }
        },
        handleCancel() {
            this.$emit('cancel')
            if (this.saving) {
                return;
            }
            this.tempValue = this.modelValue;
            this.isEditing = false;
        }
    }
}
</script>