<template>
    <form>
        <div v-if="packagesLoading" class="d-flex justify-center pt-4">
            <v-progress-circular indeterminate :size="80" :width="7" color="primary"></v-progress-circular>
        </div>
        <div v-else-if="!hasGlobalPackages">
            <div class="d-flex align-center flex-column">
                <div class="ebx-icon mb-4">
                    <img :src="assets.icons.emptyStateAssets" alt="No packages">
                </div>
                <p class="ebx-primary">We couldn't find any packages</p>
            </div>
        </div>
        <div v-else>
            <v-divider class="mb-4"></v-divider>
            <div class="d-flex justify-end">
                <v-btn color="primary" variant="text" :disabled="saving" @click="toggleAllPackages(true)">Select all</v-btn>
                <v-btn color="primary" variant="text" :disabled="saving" @click="toggleAllPackages(false)">Unselect all</v-btn>
            </div>
            <div class="mb-4">
                <div v-for="global_package in allGlobalPackages" :key="global_package.id">
                    <v-checkbox
                        :label="global_package.title" 
                        :value="global_package.id" 
                        :disabled="saving"
                        v-model="selectedPackageIds" 
                        hide-details
                    />
                </div>
            </div>  
            <v-divider class="mb-4"></v-divider>
            <div class="mb-4">
                <v-checkbox :disabled="saving" v-model="show_new_packages" hide-details label="Automatically add new packages published by Earth Blox to the Explore page" />
            </div>
            <div class="mb-12">
                <v-btn color="primary" :disabled="saving || !unsavedChanges" :loading="saving" @click="saveGlobalPackages">Save changes</v-btn>
            </div>
        </div>
    </form>
</template>

<script>
import packageMixin from '@/components/mixins/packageMixin.js';
import assetsMixin from "@/components/mixins/assetsMixin.js";
import { debounce } from 'lodash';

export default {
    mixins: [packageMixin, assetsMixin],
    data() {
        return {
            packagesLoading: true,
            selectedPackageIds: [],
            hide_new_packages: false,
            saving: false,
            debounce_function: debounce(this.saveGlobalPackages, 1000)
        }
    },
    emits: ['change'],
    computed:{
        hasGlobalPackages() {
            return this.allGlobalPackages.length > 0
        },
        allGlobalPackages() {
            return this.$store.state.packages.globalPackages || []
        },
        orgPackageVisibility() {
            return this.$store.getters['auth/orgGetGlobalPackageVisibility']
        },
        unselectedPackageIds() {
            return this.allGlobalPackages.filter(p => !this.selectedPackageIds.includes(p.id)).map(p => p.id)
        },
        show_new_packages: {
            get() {
                return !this.hide_new_packages
            },
            set(value) {
                this.hide_new_packages = !value
            }
        },
        unsavedChanges() {
            // check if toggle has changed to show new packages
            if ( this.hide_new_packages !== this.orgPackageVisibility.hide_new_packages) {
                return true
            }
            // if visible and hidden packages are empty means the admin has not selected any packages yet. wait for one to be hidden
            if (this.unselectedPackageIds.length === 0 && this.orgPackageVisibility.visible.length === 0 && this.orgPackageVisibility.hidden.length === 0) {
                return this.unselectedPackageIds > 0
            }
            // check selected and unselected variables match orgPackageVisibility within organisation
            const addedPackages = this.selectedPackageIds.filter(id => !this.orgPackageVisibility.visible.includes(id))
            const removedPackages = this.unselectedPackageIds.filter(id => !this.orgPackageVisibility.hidden.includes(id))
            return addedPackages.length > 0 || removedPackages.length > 0
        }
    },
    watch: {
        unsavedChanges(value) {
            this.$emit('change', value)
        }
    },
    mounted() {
        this.loadGlobalPackages();
    },
    methods: {
        async loadGlobalPackages() {
            try {
                this.packagesLoading = true
                await this.getGlobalPackages()
                this.updateLocalProperties()
            } finally {
                this.packagesLoading = false
            }
        },
        toggleAllPackages(enabled) {
            this.selectedPackageIds = enabled ? this.allGlobalPackages.map(p => p.id) : []
        },
        updateLocalProperties() {
                this.selectedPackageIds = this.globalPackages.map(p => p.id)
                this.hide_new_packages = this.orgPackageVisibility.hide_new_packages
        },
        async saveGlobalPackages(){
            const dataPacket = {
                visible: this.selectedPackageIds,
                hidden: this.unselectedPackageIds,
                hide_new_packages: this.hide_new_packages
            }
            try{
                this.saving = true
                await this.$store.dispatch('auth/updateOrganisationSettings', {
                    global_packages: dataPacket
                })
            } finally {
                this.saving = false
            }
        }
    }

}
</script>