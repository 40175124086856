import '@mdi/font/css/materialdesignicons.css'
import '@/styles/vuetify/main.scss'
import '@/styles/vuetify/custom.scss'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const ebxDefaultTheme = {
    dark: false,
    colors: {
        background: '#ffffff', //overall background of app
        surface: '#ffffff', // Things like modal backgrounds
        'surface-bright': '#ffffff',
        'surface-light': '#ffffff',
        'surface-variant': '#535353',
        'on-surface-variant': '#f3f3f3',
        primary: '#00ab9e',
        'primary-darken-1': '#00988c',
        secondary: '#4c6fe2',
        'secondary-darken-1': '#4362c9',
        error: '#DC1313',
        info: '#4c6fe2',
        success: '#2E7D32',
        warning: '#c18700',
        'slate-50': "#f3f3f3"
    },
    variables: {
        'border-color': '#000000',
        'border-opacity': 0.12,
        'high-emphasis-opacity': 0.87,
        'medium-emphasis-opacity': 0.75,
        'disabled-opacity': 0.38,
        'idle-opacity': 0.04,
        'hover-opacity': 0.08,
        'focus-opacity': 0.12,
        'selected-opacity': 0.08,
        'activated-opacity': 0.12,
        'pressed-opacity': 0.12,
        'dragged-opacity': 0.08,
        'theme-kbd': '#212529',
        'theme-on-kbd': '#FFFFFF',
        'theme-code': '#F5F5F5',
        'theme-on-code': '#000000',
    }
}

const VuetifyPlugin = {
    install(app) { 
        app.use(createVuetify({
            components, 
            directives,
            
            theme: {
                defaultTheme: 'ebxDefaultTheme',
                themes: {
                    ebxDefaultTheme,
                },
            },
            aliases: {
                DialogTitle: components.VToolbar
            }, 
            defaults: {
                VTextField: {
                    variant: 'outlined' 
                },
                VTextarea: {
                    variant: 'outlined' 
                },
                VBtn: { 
                    ripple: false,
                    elevation: 0
                },
                VListItem: {
                    ripple: false
                },
                VAlert: {
                    variant: 'tonal',
                    border: 'start',
                    closable: false,
                    elevation: 0,
                    icon: false
                },
                VMenu: {
                    VList: {
                        density: 'compact',
                        slim: true,
                        VListItem: {
                            class: 'py-1 px-2',
                            rounded: true,
                            variant: 'text',
                        },
                    },
                    
                    VListSubheader: {
                        style: 'padding-inline-start: 8px !important;',
                    }
                },
                VSwitch: {
                    inset: false,
                    color: 'primary',
                },
                VCheckbox: {
                    color: 'primary',
                },
            },
            date: {
                locale: {
                    en: 'en-GB',
                },
            }
        }))
    }
}

export { VuetifyPlugin }