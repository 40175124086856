<template>
    <div class="dataset-viewer ebx-dialog modal-xl" v-if="showViewer">
        <div class="dataset-viewer--container">
            <div class="dataset-viewer--top-actions">
                <button class="ebx-icon-button ebx-icon-button__no-shadow" @click="showViewer = false"><span class="material-icons-outlined ebx-icon">arrow_back</span></button>
                <div class="dataset-viewer--title">
                    <h2 class="ebx-primary text-slate-600">Select dataset</h2>
                </div>
                <button class="ebx-icon-button ebx-icon-button__no-shadow" @click="closeDatasetsSelect"><span class="material-icons-outlined ebx-icon">close</span></button>
            </div>

            <div class="dataset-viewer--summary">
                <template v-if="hasMetaData">
                    <div v-if="hasThumbnail(thumbnailSrc)" class="dataset-viewer--thumbnail">
                        <img :src="thumbnailSrc" :alt="metaData.title" />
                    </div>
                    <div v-else class="dataset-viewer--thumbnail">
                        <img :src="assets.icons.datasetImagePlaceholder" :alt="metaData.title">
                    </div>
                </template> 
                <div class="dataset-viewer--title-section">
                    <div class="dataset-viewer--item-title">
                        <p class="ebx-primary-bold">{{datasetTitle}}</p>
                        <div v-if="isPaid" class="paid-tag">
                            <p class="ebx-secondary">Paid access</p>
                            <md-icon class="dataset-lock-icon">lock</md-icon>
                        </div>
                    </div>
                    <div class="dataset-viewer--sub-title" v-if="hasShortDescription">
                        <p class="ebx-primary">{{metaData['ebx:short_description']}}</p>
                    </div>
                </div>
            </div>

            <EbxTabs :hide-actions-if-not-multiple="true">
                <EbxTab title="Overview" id="overview">
                    <div class="dataset-viewer--body">
                        <div class="dataset-viewer--overview-panel">
                            <div class="dataset-viewer--overview-container">
                                <!-- Dataset stats -->
                                <div class="dataset-viewer--dataset-info">
                                    <div class="dataset-viewer--property-section" v-if="cadence">
                                        <div class="dataset-viewer--property-title">
                                            <p class="ebx-secondary">Frequency</p>
                                        </div>
                                        <div class="dataset-viewer--property-content">
                                            <p class="ebx-primary-bold ">{{ cadence.displayText}}</p>
                                        </div>
                                    </div>
                                    <div class="dataset-viewer--property-section" v-if="showTemporalExtent">
                                        <div class="dataset-viewer--property-title">
                                            <p class="ebx-secondary">Date range</p>
                                        </div>
                                        <div class="dataset-viewer--property-content">
                                            <p class="ebx-primary-bold">{{ startDate }} &ndash; {{ endDate }}</p>
                                        </div>
                                    </div>
                                    <div class="dataset-viewer--property-section" v-if="hasResolution">
                                        <div class="dataset-viewer--property-title">
                                            <p class="ebx-secondary">Pixel size</p>
                                        </div>
                                        <div class="dataset-viewer--property-content">
                                            <p class="ebx-primary-bold">{{resolution}} metres</p>
                                        </div>
                                    </div>
                                    <div class="dataset-viewer--property-section" v-if="isSpatialExtent">
                                        <div class="dataset-viewer--property-title">
                                            <p class="ebx-secondary">Coverage</p>
                                        </div>
                                        <div class="dataset-viewer--property-content">
                                            <div class="google-map" ref="googleMap" />
                                        </div>
                                    </div>
                                    <div class="dataset-viewer--property-section" v-if="hasProviders">
                                        <div class="dataset-viewer--property-title">
                                            <p class="ebx-secondary">{{providers.length > 1 ? 'Providers' : 'Provider'}}</p>
                                        </div>
                                        <div class="dataset-viewer--property-content">
                                            <a 
                                                class="ebx-link ebx-link__light" 
                                                v-for="(provider, index) in providers" 
                                                :key="index" 
                                                :href="provider.url" 
                                                target="_blank">{{provider.name}}</a>
                                        </div>
                                    </div>
                                    <div class="dataset-viewer--property-section" v-if="terms !== null">
                                        <div class="dataset-viewer--property-title">
                                            <p class="ebx-secondary">License</p>
                                        </div>
                                        <div class="dataset-viewer--property-content">
                                            <a class="ebx-link ebx-link__light" :href="terms" >{{ metaData['license'] }}</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Dataset description -->
                                <div v-if="hasDescription" class="dataset-viewer--dataset-description">
                                    <div class="ebx-primary markdown-text" v-html="renderedMarkdown" @click="checkLinkClick"></div>
                                </div>
                                <div v-else class="dataset-viewer--dataset-description">
                                    <p class="ebx-primary">No description entered</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </EbxTab>
                <EbxTab v-if="metaData && metaData.bands" title="Bands" id="bands">
                    <md-table>
                        <md-table-row>
                            <md-table-head>Name</md-table-head>
                            <md-table-head>Description</md-table-head>
                        </md-table-row>
                        <md-table-row v-for="band in ebxBands" :key="band.name">
                            <md-table-cell>{{ band['ebx:name'] }}</md-table-cell>
                            <md-table-cell>{{ band['ebx:short_description'] }}</md-table-cell>
                        </md-table-row>
                    </md-table>
                </EbxTab>
            </EbxTabs>

            <div :class="actionsClassList">
                <div v-if="isPaid">
                    <p class="ebx-primary-bold">You don't have access to this dataset</p>
                    <p class="ebx-primary">This dataset is paid access only. Contact support to enquire about getting access.</p>
                    <div class="supportButton"> 
                    <EbxButton
                        theme="secondary__highlight"
                        @click="contactSupport">
                        {{supportButtonText}}
                    </EbxButton>
                </div>
                </div>
                <div v-if="hasDefaultModifiers && (datasetId !== blockDatasetId) && !isPaid" class="">
                    <md-checkbox class="ebx-checkbox" v-model="addRecommenedBlocks">Add recommended blocks</md-checkbox>
                </div>
                <div v-if="!isPaid">
                    <EbxButton
                        :disabled="!hasMetaData" 
                        theme="primary"
                        @click="useDataset">
                        {{okayText}}
                    </EbxButton>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import moment from 'moment'
import { firebaseConfig } from "@/firebase.js";
import { Loader } from "@googlemaps/js-api-loader";
import {EBX_DATASET_TITLE, TITLE} from '../constants/ebxStacConstants';
import EbxButton from '@/components/EbxComponents/EbxButton.vue';
import EbxTabs from '@/components/EbxComponents/EbxTabs.vue';
import EbxTab from '@/components/EbxComponents/EbxTab.vue';
import { DatasetService } from '@/services/dataset.service.js';
import valueMixin from '@/components/mixins/valueMixin';
import assetsMixin from "@/components/mixins/assetsMixin.js" 
import * as commonmark from 'commonmark';
import { toRaw } from 'vue';

export default {
    name: 'DatasetViewer',
    components: {
        EbxButton,
        EbxTabs,
        EbxTab
    },
    mixins: [valueMixin, assetsMixin],
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        datasetId: {
            type: String,
            default: ''
        },
        blockDatasetId: {
            type: String,
            default: ''
        },
        metaData: {
            type: Object,
            default: null
        },
        ebxBands: {
            type: Array,
            default: function() {
                return []
            }
        },
        recommenedBlocks: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'update:modelValue',
        'close-datasets-select',
        'insert-dataset',
        'update-recommened-blocks'
    ],
    data() {
        return {
            loading: false,
            errored: false,
            google: null,
            map: null,
            rect: null,
            standardisedModifiers: null, 
            supportButtonText: 'Contact support',

        }
    },
    computed: {
        okayText() {
            if(this.datasetId === this.blockDatasetId) {
                return 'Okay'
            } else if (!this.blockDatasetId || this.blockDatasetId === 'nodata') {
                return 'Select dataset'
            } else {
                return 'Change dataset'
            }
        },
        actionsClassList() {
            if (this.datasetId === this.blockDatasetId) {
                return 'dataset-viewer--bottom-actions dataset-viewer--bottom-actions__right'
            } else {
                return 'dataset-viewer--bottom-actions'
            }
        },
        hasResolution() {
            return this.hasMetaData && this.metaData.gsd && this.metaData.gsd.length > 0
        },
        hasDescription() {
            return this.hasMetaData && this.metaData.description !== null && this.metaData.description.length > 0
        },
        isPaid() { 
            return this.hasMetaData && this.metaData.isPaid === true
        },
        hasShortDescription() {
            return this.hasMetaData && this.metaData['ebx:short_description'] !== null && this.metaData['ebx:short_description'].length > 0
        },
        mapsApiKey() {
            return firebaseConfig.apiKey;
        },
        mapConfig() {
            return {
                zoom: 1,
                center: { lat: 0, lng: 0 },
                mapTypeId: "terrain"
            };
        },
        thumbnailSrc() {
            if(this.hasMetaData && this.metaData.thumbnail_url) {
                return this.metaData.thumbnail_url
            }
            return null
        },
        spatialExtent() {
            if(this.isSpatialExtent) {
                return this.metaData.extent.spatial.bbox
            }
            return [0,0,0,0]
        },
        hasMetaData() {
            return this.loading === false && this.errored === false && this.metaData !== null
        },
        hasProviders() {
            return this.hasMetaData && Array.isArray(this.metaData.providers)
        },
        terms() {
            if(this.hasMetaData && this.metaData.license_url) {
                return this.metaData.license_url
            }
            return null
        },
        isCommercialUseAllowed() {
            if(this.hasMetaData && this.metaData.license_allows_commercial_use) {
                return this.metaData.license_allows_commercial_use
            }
            return null
        },
        hasDefaultModifiers() {
            return this.hasMetaData && Array.isArray(this.standardisedModifiers) && this.standardisedModifiers.length > 0
        },
        showTemporalExtent() {
            return this.hasMetaData && (moment(this.metaData.start_date).isValid() || moment(this.metaData.end_date).isValid())
        },
        isSpatialExtent() {
            let current = this.hasMetaData && this.metaData.extent && this.metaData.extent.spatial  && this.metaData.extent.spatial.bbox
            this.doMapInit(current)
            return current
        },
        hasCadence() {
            return this.hasMetaData && this.metaData["ebx:cadence"]
        },
        datasetTitle() {
            if(this.hasMetaData) {
                return this.metaData[EBX_DATASET_TITLE] || this.metaData[TITLE]
            }
            return null
        },
        startDate() {
            if(this.showTemporalExtent) {
                const date = moment(this.metaData.start_date)
                return date.isValid() ? date.format('DD/MM/YYYY') : ''
            }
            return null
        },
        endDate() {
            if(this.showTemporalExtent) {
                const date = moment(this.metaData.end_date)
                return date.isValid() ? date.format('DD/MM/YYYY') : 'current'
            }
            return null
        },
        cadence() {
            if(this.hasCadence) {
                let cadenceObj = Object.assign({}, toRaw(this.metaData["ebx:cadence"]))
                if (cadenceObj.unit === 'Single image') { 
                    cadenceObj.displayText =  cadenceObj.unit
                } else if (cadenceObj.interval == 1 && cadenceObj.unit === 'day') {
                    cadenceObj.displayText = "daily"
                } else if (cadenceObj.interval == 1) {
                    cadenceObj.displayText = cadenceObj.unit + "ly"
                } else {
                    cadenceObj.displayText = cadenceObj.interval + " " + cadenceObj.unit + "s"
                }
                cadenceObj.displayText = cadenceObj.displayText.charAt(0).toUpperCase() + cadenceObj.displayText.slice(1)
                return cadenceObj
            }
            return null
        },
        resolution() {
            if(this.hasResolution) {
                if (this.metaData.gsd.length === 1) {
                    return this.metaData.gsd[0]
                } else {
                    // filter for lowest to highest value
                    const sortedGsd = this.metaData.gsd.filter(gsd => gsd !== null).sort((a, b) => a - b)
                    return `${sortedGsd[0]} – ${sortedGsd[sortedGsd.length-1]}`
                }
            }
            return null
        },
        providers() {
            if(this.hasProviders) {
                return this.metaData.providers
            }
            return []
        },
        showViewer: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        addRecommenedBlocks: { 
            get() {
                return this.recommenedBlocks
            },
            set(value) {
                this.$emit('update-recommened-blocks', value)
                return value
            }
        },
        renderedMarkdown() {
            let description 
            if ('ebx:description' in this.metaData === true) {
                description = this.metaData['ebx:description'].replaceAll('    ', '')
            } else {
                description = this.metaData.description.replaceAll('    ', '')
            }
            const reader = new commonmark.Parser();
            const parsed = reader.parse(description);
            const writer = new commonmark.HtmlRenderer();
            return writer.render(parsed);
        },
    },
    watch: {
        async datasetId(datasetId) {
            if (datasetId === null) {
                return
            }

            try {
                this.standardisedModifiers = await DatasetService.getDefaultModifiers(datasetId)
                this.errored = false
                this.loading = true
            } catch(error) {
                console.error(error)
                this.errored = true
            } finally {
                this.loading = false
            }
        },
    },
    methods: {
        async doMapInit(current) {
            if(current) {
                this.map = null
                const loader = new Loader({
                    apiKey: this.mapsApiKey,
                    libraries: ["geometry", "drawing"], // MUST BE DOUBLE QUOTES ""
                    version: "weekly"
                })
                loader.load().then((google) => {
                    this.google = google;
                    this.initializeMap()
                    this.setRect()
                }).catch(e => {
                    console.error("Failed to load map", e)
                })
                
            }
        },
        checkLinkClick(event) {
            if(event.target.tagName === 'A') {
                event.preventDefault()
                window.open(event.target.href, '_blank')
            }
        },
        closeDatasetsSelect() {
            this.showViewer = false
            this.$emit('close-datasets-select')
        },
        hasThumbnail(url) {
            // regex check for url
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g
            return regex.test(url)
        },
        async useDataset() {
            this.showViewer = false
            this.$emit('insert-dataset', this.datasetId, this.addRecommenedBlocks ? this.standardisedModifiers : [])
        },
        initializeMap() {
            if(this.map !== null) {
                return
            }
            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
        },
        getMinMax(band) {
            if(this.hasMetaData && this.metaData['ebx:visualize_v2'] && this.metaData['ebx:visualize_v2'][band]) {
                return {
                    min: this.metaData['ebx:visualize_v2'][band].min,
                    max: this.metaData['ebx:visualize_v2'][band].max
                }
            }
            return {
                min: null,
                max: null
            }
        },
        setRect() {
            const bounds = {
                north: this.spatialExtent[3],
                south: this.spatialExtent[1],
                east: this.spatialExtent[2],
                west: this.spatialExtent[0]
            }
            
            this.rect = new this.google.maps.Rectangle({
                strokeColor: "#E7BA00",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#E7BA00",
                fillOpacity: 0.35,
                map: this.map,
                bounds
            });

        }, 
        contactSupport() {
            const supportEmail = 'support@earthblox.io'; 
            const subject = 'Access request (Add dataset name here)'; 
            const datasetTitle = this.metaData.title;
            const body = `I’d like to request access to ${datasetTitle}, please contact me to discuss licensing options`; 
            const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.open(mailtoLink);
        }
    },

}
</script>