<template>
    <div :class="cardClasses" tabindex="0">

        <div class="ebx-card--check-background" v-if="selectable">
            <v-checkbox 
                class="ebx-card--check"
                tabindex="0"
                color="var(--pine-400)"
                base-color="primary" 
                :ripple="false"
                density="comfortable"    
                v-model="checked"/>
        </div>
        
        <div class="ebx-card--image" :class="imageBackgroundClass">
            <slot name="image">
                <img :src="src" alt="Placeholder image">
            </slot>
        </div>
        
        <div class="ebx-card--content">
            <!--Card content including text and title-->
            <div class="ebx-card--media">
                <div class="ebx-card--media-content">
                    <p v-if="title" class="ebx-primary-bold ebx-card--title">{{title}}</p>
                    <p v-if="text" class="ebx-secondary ebx-card--text">{{text}}</p>
                
                </div>
            </div>
        </div>
        <div :class="actionsClasses" v-if="actions && !selectable">
            <div class="ebx-card--actions-container">
                <v-btn 
                    v-if="showLeftAction" 
                    color="primary"
                    variant="tonal"
                    rounded="true"
                    density="compact"
                    @click="$emit('action-left-click')">
                    {{actionLeftText}}
                </v-btn> 
                <v-btn 
                    v-if="showRightAction" 
                    color="primary"
                    variant="text"
                    rounded="true"
                    density="compact"
                    @click="$emit('action-right-click')">
                    {{actionRightText}}
                </v-btn>
            </div>
        </div>
    </div>
</template>


<script>
import assets from '@/assets.js'
import assetMixin  from "@/components/mixins/assetsMixin.js"

export default {
    mixins: [
        assetMixin
    ],
    props: {
        modelValue: {
            type: Boolean,
            required: false,
            default: false
        },
        imgSrc: {
            type: String,
            required: false,
            default: assets.icons.globe
        },
        title: {
            type: String,
            required: false,
            default: ""
        },
        text: {
            type: String,
            required: false,
            default: ""
        },
        actionLeftText: {
            type: String,
            required: false,
            default: "Open"
        },
        actionRightText: {
            type: String,
            required: false,
            default: "Details"
        },
        showLeftAction: {
            type: Boolean,
            required: false,
            default: false
        },
        showRightAction: {
            type: Boolean,
            required: false,
            default: false
        },
        selectable:{
            type: Boolean,
            required: false,
            default: false
        },
        isBlank: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'action-left-click',
        'action-right-click',
        'update:modelValue'
    ],
    
    computed: {
        usingGlobeAsPlaceholder() {
            return this.src === this.assets.icons.globe
        },
        imageBackgroundClass() {
            if (this.isBlank) {
                return ""
            } else if (this.usingGlobeAsPlaceholder) {
                return "ebx-card--figure-globe"
            } else {
                return ""
            }
        },
        src() {
            if (this.imgSrc === null || this.imgSrc === undefined || this.imgSrc === "") {
                return this.assets.icons.globe
            }

            return this.imgSrc
        },
        actions() {
            if (this.showLeftAction || this.showRightAction) {
                return true
            } else {
                return false
            }
        },
        actionsClasses() {
            if (this.actionLeftText && this.actionRightText) {
                return "ebx-card--actions"
            } else if (this.actionLeftText) {
                return "ebx-card--actions ebx-card--actions__left"
            } else if (this.actionRightText) {
                return "ebx-card--actions ebx-card--actions__right"
            } else {
                return ""
            }
        },
        cardClasses() {
            if (this.checked) {
                return "ebx-card ebx-card__checked"
            } else if (this.actions) {
                return "ebx-card ebx-card__with-actions"
            } else {
                return "ebx-card"
            }
        },
        checked: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>