<template>
    <VEbxModal 
        toolbar-title="Add template to package" 
        v-model="showAddToPackage" 
        cancel-button-text="Cancel"
        :cancel-button-show="canAdd"
        :confirmation-button-text="canAdd ? 'Add' : 'Okay'"
        :confirmationButtonDisabled="canAdd && !selectedPackage"
        :confirmation-close-on-action="true"
        confirmation-button-variant="flat"
        persistent
        @confirm="updatePackage"
        @cancel="clearSelection"
        @dismiss="clearSelection"
        width="500px"
        :show-divider="false"
        size="xs"
    >
        <template v-slot:default>
            <div v-if="!canAdd">
                    <p>You don't have any team packages yet. Please create one first.
                    <a href="https://support.earthblox.io/support/solutions/articles/101000543049-packages-in-earth-blox" target="_blank">Learn more about creating packages for your team.</a></p>
            </div>
            <div v-else>
                <v-select 
                    v-model="selectedPackage"
                    :items="packagesItems"
                    variant="outlined"
                    label="Choose package"
                    :hide-details="true"
                />
            </div>
            
        </template>
    </VEbxModal>
</template>

<script>
import VEbxModal from '@/components/EbxComponents/VEbxModal.vue';

export default {
    components: {
        VEbxModal
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        packagesList: {
            type: [Array, null],
            required: true,
            default: () => []
        }
    },
    emits: [
        'update:modelValue',
        'add'
    ],
    data() {
        return {
            showAddToPackage: false,
            loadingTeamPackages: false,
            selectedPackage: null
        }
    },
    computed: {
        packagesItems() {
            if (this.packagesList.length === 0) {
                return []
            }
            return this.packagesList.map(p => {
                return {
                    title: p.title,
                    value: p.id
                }
            })
        },
        canAdd() {
            if (this.packagesList === null) {
                return false
            }
            return this.packagesList.length !== 0
        }
    },
    methods: {
        async updatePackage() {
            if (this.selectedPackage) {
                const packageToUpdate = this.packagesList.find(p => p.id === this.selectedPackage)
                this.$emit('add', packageToUpdate)
            }
            this.clearSelection()
        },
        clearSelection() {
            this.showAddToPackage = false
            this.selectedPackage = null
        }
    },
    watch: {
        modelValue: {
            handler(value) {
                this.showAddToPackage = value
            },
            immediate: true
        },
        showAddToPackage: {
            handler(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
}
</script>
