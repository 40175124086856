<template>
    <div>
        <v-text-field
            v-model="packageTitle"
            variant="outlined"
            label="Name"
            :error="errors.title.error"            
            :error-messages="errors.title.errorMessage"
            />
        <v-textarea
            v-model="packageDescription"
            variant="outlined"
            label="Description"
            :error="errors.description.error"
            hide-details="auto"
            :error-messages="errors.description.errorMessage"
        />
    </div>
</template>

<script>
import packageMixin from '../mixins/packageMixin';

export default {
    name: 'PackageCreationForm',
    emits: ['update:packageInfo'],
    props: {
        prefilledTitle: {
            type: String,
            required: false,
            default: ''
        },
        prefilledDescription: {
            type: String,
            required: false,
            default: ''
        },
        packageType: {
            type: String,
            required: true
        }
    },
    mixins: [packageMixin],
    data() {
        return {
            errors: {
                title: {
                    error: false,
                    errorMessage: ''
                },
                description: {
                    error: false,
                    errorMessage: ''
                }
            },
        }
    },
    methods: {
        setTitleError(show, message) {
            this.errors.title.error = show;
            this.errors.title.errorMessage = message;
        },
        setDescriptionError(show, message) {
            this.errors.description.error = show;
            this.errors.description.errorMessage = message;
        }
    },
    computed: {
        packageTitle: {
            get() {
                return this.prefilledTitle;
            },
            set(value) {
                const error = this.validatePackageName(value, this.packageType);
                if (!error) {
                    this.setTitleError(false, '');
                } else {
                    this.setTitleError(error.exists, error.message);
                }
                this.$emit('update:packageInfo', {
                    title: value.trim(),
                    error: this.errors.title.error,
                });
            }
        },
        packageDescription: {
            get() {
                return this.prefilledDescription;
            },
            set(value) {
                const error = this.validatePackageDescription(value);
                if (!error) {
                    this.setDescriptionError(false, '');
                } else {
                    this.setDescriptionError(error.exists, error.message);
                }
                this.$emit('update:packageInfo', {
                    description: value,
                    error: this.errors.description.error,
                });
            }
        }
    }
}
</script>