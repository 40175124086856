<template>
    <EBXMenuLayout>
        <router-view></router-view>
    </EBXMenuLayout>
</template>

<script>
import EBXMenuLayout from '../../layouts/EBXMenuLayout.vue';

export default {
    components: {
        EBXMenuLayout
    }
}
</script>