<template>
    <div>
        <EbxTemplateWorkflows 
            v-model="loadingTemplates"
            :show-search-bar="true"
            :exclude-team-templates="excludeTeamTemplates"
            :selectableTemplates="true"
            :tags-hidden="true" 
            />
    </div>
</template>

<script>
import packageMixin from "@/components/mixins/packageMixin";
import EbxTemplateWorkflows from '@/components/EbxComponents/EbxTemplateWorkflows.vue'

export default {
    name: 'ProjectsChooser',
    mixins: [packageMixin],
    components: {
        EbxTemplateWorkflows,
    },
    props: {
        packageType: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue', 'update:templatesLoading'],
    computed: {
        excludeTeamTemplates() {
            // Exclude team templates if the package type is global because we can't show team templates to users in other teams
            return this.packageType === 'global';
        },
        loadingTemplates: {
            get() {
                return this.loading;
            },
            set(value) {
                this.$emit('update:templatesLoading', value)
            }
        }
    }
}
</script>