<template>
    <VEbxModal 
        :toolbar-title="modalTitle" 
        v-model="showPackageCreation" :size="modalSize" 
        :cancel-button-text="cancelButtonText"
        :confirmation-button-text="confirmButtonText"
        :confirmation-button-disabled="!canContinue"
        :confirmation-close-on-action="false"
        :confirmation-button-loading="confirmLoading || templatesLoading"
        :confirmation-button-variant="(confirmLoading || templatesLoading) ? 'tonal' : 'flat'"
        :dismiss-close-on-action="false"
        :cancel-close-on-action="false"
        :height="modalHeight"
        scrollable
        @afterLeave="tidyUp"
        @dismiss="handleCancelRequest"
        @cancel="cancelButtonText === 'Cancel' ? handleCancelRequest() : handleConfirmation(false)"
        @confirm="showTemplates ? handleConfirmation() : handleNext()"
    >   
        <template v-slot:default>
            <PackageCreationForm 
                v-if="!showTemplates"
                :package-type="packageType"
                :prefilled-title="packageTitle"
                :prefilled-description="packageDescription"
                @update:packageInfo="handleUpdatePackageInfo"
            />
            <ProjectsChooser 
                v-else
                :package-type="packageType"
                :loading="templatesLoading"
                @update:templatesLoading="templatesLoading = $event"
            />
        </template>
    </VEbxModal>
</template>

<script>
import VEbxModal from '@/components/EbxComponents/VEbxModal.vue';
import PackageCreationForm from './PackageCreationForm.vue';
import ProjectsChooser from './ProjectsChooser.vue';
import packageMixin from "@/components/mixins/packageMixin";
import authMixin from "@/components/mixins/authMixin";
import { PROJECT_MODE } from '@/components/mixins/projectMixin';

export default {
    components: {
        VEbxModal,
        PackageCreationForm,
        ProjectsChooser
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        packageType: { 
            type: String, 
            required: true
        },
        newPackage: {
            type: Boolean,
            default: true,
            required: true
        },
        editPackage: {
            type: Object,
            default: null,
            required: false
        }
    },
    mixins: [packageMixin, authMixin],
    emits: ['update:modelValue'],
    data() {
        return {
            showPackageCreation: false,
            confirmLoading: false,
            templatesLoading: false,
            packageTitle: '',
            packageDescription: '',
            modalHeight: 'auto',
            invalidForm: false,
        }
    },
    created() {
        if (this.editPackage) {
            this.packageTitle = this.editPackage.title
            this.packageDescription = this.editPackage.description
        }
    },
    computed: {
        modalTitle() {
            if (this.showTemplates) {
                return 'Add templates'
            }
            return this.packageType === 'global' ? 'New Earth Blox package' : 'New package'
        },
        modalSize() {
            return this.showTemplates ? 'l' : 'm'
        },
        confirmButtonText() {
            if (!this.showTemplates) {
                return 'Next'
            } else if (this.editPackage) {
                return 'Save changes'
            } else if (this.templatesSelected.length > 0) {
                return `Add ${this.templatesSelected.length} ${this.templatesSelected.length > 1 ? 'templates' : 'template'}`
            } else {
                return 'Add'
            }
        },
        cancelButtonText() {
            if (this.showTemplates) {
                if (this.editPackage) {
                    return 'Cancel'
                } else {
                    return 'Skip'
                }
            } else {
                return 'Cancel'
            }
        },
        showTemplates: {
            get() {
                return !this.newPackage
            },
            set(value) {
                this.setAddNewPackage(!value)
            }
        },
        canContinue: {
            get() {
                if (this.showTemplates) {
                    return this.templatesSelected.length > 0
                } else if (this.packageTitle === '') {
                    return false
                } else if (this.invalidForm) {
                    return false
                }
                return true
            },
            set(value) {
                return value
            }
        },
        templatesSelected() {
            if (!this.projectsToAdd) {
                return []
            }
            return this.projectsToAdd.map(id => this.getTemplateById(id))
        },
    },
    methods: {
        tidyUp() {
            this.confirmLoading = false
            this.templatesLoading = false
            this.showTemplates = false
            this.setProjectsToAdd([])
            this.setPackageToEdit(null)
            this.packageTitle = ''
            this.packageDescription = ''
            this.modalHeight = 'auto'
        },
        handleCancelRequest() { 
            this.tidyUp()
            this.showPackageCreation = false
        }, 
        handleNext() { 
            this.showTemplates = true
            this.templatesLoading = true
            this.canContinue = false
        },
        async handleConfirmation(updateTemplates = true) {
            // construct the data object to send to the API
            let data = {}
            if (updateTemplates) {
                data.projects_assigned = this.constructProjectsAssigned()
            }
            this.confirmLoading = true
            let actionType = ''
            // check if we are editing a package
            if (this.editPackage && this.editPackage.id) {
                actionType = 'update'
            } else {
                // use the edited package title and description if they exist, otherwise use the prefilled values
                data.title = this.packageTitle || this.editPackage?.title
                if (this.packageDescription || this.editPackage?.description) {
                    data.description = this.packageDescription || this.editPackage.description
                }
                data.type = this.packageType
                actionType = 'create'
            }

            // call the API to create or update the package
            try {
                var response = null
                if (actionType === 'update') {
                    await this.updatePackage(this.editPackage.id, data)
                } else if (actionType === 'create') {
                    response = await this.createPackage(data)
                }
                this.confirmLoading = false
                this.tidyUp()
                if (actionType === 'create') {
                    // go to page of the newly created package
                    this.goToPackagePage(response.data?.id)
                }
                this.showPackageCreation = false
            } catch (error) {
                console.error(`Error with package ${actionType}`, error)
                this.confirmLoading = false
            }
        },
        goToPackagePage(id) {
            if (!id) {
                return
            }
            this.$router.push( {
                name:'Package',
                params: {
                    packageId: id,
                }
            })
        },
        handleUpdatePackageInfo(packageObject) {
            this.invalidForm = false
            if (packageObject.title || packageObject.title === '') {
                this.packageTitle = packageObject.title
            }
            if (packageObject.description || packageObject.description === '') {
                this.packageDescription = packageObject.description
            }
            if (packageObject.error) {
                this.invalidForm = true
            }
        },
        constructProjectsAssigned() {
            let projects_assigned = []
            if (this.templatesSelected.length > 0) {
                this.templatesSelected.forEach(template => {
                    if (template.mode === PROJECT_MODE.GLOBAL_TEMPLATE) {
                        projects_assigned.push({ref: `shared/default/workflows/${template.id}`})
                    } else if (template.mode === PROJECT_MODE.ORG_TEMPLATE) {
                        projects_assigned.push({ref: `organisations/${this.user.orgId}/published_workflows/${template.id}`})
                    }
                })
            }
            return projects_assigned
        },
    },
    watch: {
        modelValue: {
            handler(value) {
                this.showPackageCreation = value
            },
            immediate: true
        },
        showPackageCreation: {
            handler(value) {
                this.$emit('update:modelValue', value)
            }
        },
        editPackage: {
            handler(value) {
                if (value) {
                    this.packageTitle = value.title
                    this.packageDescription = value.description || ''
                }
            },
            deep: true
        },
        showTemplates: {
            handler(value) {
                if (value) {
                    this.modalHeight = '70vh'
                } else {
                    this.modalHeight = 'auto'
                }
            }
        }
    }
}
</script>
