import { sharedCollection, organisationsCollection } from '@/firebase.js';
import { doc as firestoreDoc, getDoc, collection, onSnapshot } from "firebase/firestore";
import { PROJECT_MODE } from '@/components/mixins/projectMixin';
import assets from "@/assets.js"
import api from '@/services/ebx-api.service.js'

const state = {
    globalPackages: null,
    teamPackages: null,
    globalProjects: null,
    teamProjects: null,
    globalProjectsTags: null,
    subscriptions: {
        globalProjects: null,
        teamProjects: null
    },
    operationSnapshotUnsubscribe: null,
    showModal: false,
    modalType: 'team',
    addNewPackage: false,
    packageToEdit: null,
    projectsToAdd: []
}

const getters = {
    getPackageById: (state) => (id) => {
        let allPackages = [];

        if (!state.globalPackages && !state.teamPackages) {
            return null;
        } else if (!state.globalPackages) {
            allPackages = state.teamPackages;
        } else if (!state.teamPackages) {
            allPackages = state.globalPackages;
        } else {
            allPackages = [...state.globalPackages, ...state.teamPackages];
        }

        if (allPackages) {
            return allPackages.find(pkg => pkg.id === id);
        } else {
            return null;
        }
    },
}

const mutations = {
    setGlobalPackages(state, packages) {
        state.globalPackages = packages;
    }, 
    setTeamPackages(state, packages) { 
        state.teamPackages = packages;
    },
    setGlobalProjects(state, projects) {
        state.globalProjects = projects;
    },
    setTeamProjects(state, projects) {
        state.teamProjects = projects;
    },
    setGlobalProjectsTags(state, tags) {
        state.globalProjectsTags = tags
    },
    setSubscription(state, { key, value }) {
        state.subscriptions[key] = value;
    }, 
    setUnsubscribeListener(state, unsubscribe) {
        state.operationSnapshotUnsubscribe = unsubscribe;
    }, 
    setShowModal(state,showModal) { 
        state.showModal = showModal
    },
    setModalType(state, type) {
        state.modalType = type
    },
    setAddNewPackage(state, addNewPackage) {
        state.addNewPackage = addNewPackage
    },
    setPackageToEdit(state, pkg) {
        state.packageToEdit = pkg
    },
    setProjectsToAdd(state, projects) {
        state.projectsToAdd = projects
    }
}

const actions = {
    async setPackageData(context, data) {    
        await Promise.all(
            data.packages.map(async (pkg) => {
                let projectIds = [];
                let projectThumbnails = [];
                if (Array.isArray(pkg.projectsAssigned) && pkg.projectsAssigned.length > 0) {
                    for (let i = 0; i < pkg.projectsAssigned.length; i++) {
                        const project = pkg.projectsAssigned[i];

                        if (project.ref) {
                            //get the project document from ref
                            const projectDoc = await getDoc(project.ref);
                            if (projectDoc.exists()) {
                                projectIds.push(projectDoc.id);
                                projectThumbnails.push(projectDoc.data().thumbnail || assets.icons.globe);
                            }
                        }
                    }
                }
                pkg.projectsAssigned = {
                    projectIds,
                    projectThumbnails
                
                }
            })
        )
        context.commit(data.commit, data.packages);
    },
    async getGlobalPackages(context) {
        if (context.state.globalPackages) {
            return context.state.globalPackages;
        }

        return new Promise((resolve, reject) => {
            const ref = firestoreDoc(sharedCollection, 'default');
            const packagesCollection = collection(ref, 'packages');

            let packages = [];

            const unsubscribe = onSnapshot(( packagesCollection ), async (snapshot) => {
                packages = snapshot.docs.map((doc) => ({ id: doc.id, type: 'global', ...doc.data() })); 
                await context.dispatch('setPackageData', {
                    packages,
                    commit: 'setGlobalPackages', 
                    context});
                resolve(packages);
            }, reject);

            context.commit('setUnsubscribeListener', unsubscribe);
        });
        
    },
    async getTeamPackages(context, orgId) {
        if (context.state.teamPackages) {
            return context.state.teamPackages;
        }
        const ref = firestoreDoc(organisationsCollection, orgId);

        const packagesCollection = collection(ref, 'packages');

        let packages = [];

        const unsubscribe = onSnapshot(( packagesCollection ), async (snapshot) => {
            packages = snapshot.docs.map((doc) => ({ id: doc.id, type: 'team', ...doc.data() })); 
            await context.dispatch('setPackageData', {
                packages,
                commit:'setTeamPackages', 
                context});
        })

        context.commit('setUnsubscribeListener', unsubscribe);
    },
    async getGlobalProjects(context) {
        if (context.state.subscriptions.globalProjects !== null) {
            return context.state.globalProjects;
        }
        const ref = firestoreDoc(sharedCollection, 'default');
        const projectsCollection = collection(ref, 'workflows');
        return new Promise((resolve, reject) => {
            const subscription = onSnapshot(projectsCollection, projects => {
                try {
                    const mappedProjects = projects.docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                            mode: PROJECT_MODE.GLOBAL_TEMPLATE
                        }
                    });
                    context.commit('setGlobalProjects', mappedProjects);
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
            context.commit('setSubscription', { key: 'globalProjects', value: subscription });
        });
    },
    async getTeamProjects(context, orgId) {
        if (context.state.subscriptions.teamProjects !== null) {
            return context.state.teamProjects;
        }
        const ref = firestoreDoc(organisationsCollection, orgId);
        const projectsCollection = collection(ref, 'published_workflows');
        return new Promise((resolve, reject) => {
            const subscription = onSnapshot(projectsCollection, projects => {
                try {
                    const mappedProjects = projects.docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                            mode: PROJECT_MODE.ORG_TEMPLATE
                        }
                    });
                    context.commit('setTeamProjects', mappedProjects);
                    
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
            context.commit('setSubscription', { key: 'teamProjects', value: subscription });
        });
    },
    async getGlobalProjectsTags(context) { 
        if (context.state.globalProjectsTags) {
            return context.state.globalProjectsTags;
        }

        const docRef = firestoreDoc(sharedCollection, 'default'); 
        const tags = await getDoc(docRef)
        const tagData = tags.data()
        const tagsList = tagData["tags"]
        context.commit('setGlobalProjectsTags', tagsList)
    },
    async createPackage(context, data) {
        // call api to create package
        const response = await api.packages.create(data);
        return response;
    },
    async updatePackage(context, updateObj) {
        // call api to update package
        const id = updateObj.id;
        const data = updateObj.data;
        const response = await api.packages.update(id, data);
        return response;
    },
    async deletePackage(context, id) {
        // call api to delete package
        const response = await api.packages.delete(id);
        return response;
    },
    unregisterAllSubscription(context, {key}) {
        if (context.state.subscriptions[key] !== null) {
            context.state.subscriptions[key](); // unsubscribe
            context.commit(key, null) // reset templates
            context.commit('setSubscription', { key, value: null })
        }
    },
    unregisterAllSubscriptions(context) {
        context.dispatch('unregisterAllSubscription', {key: 'globalProjects'})
        context.dispatch('unregisterAllSubscription', {key: 'teamProjects'})
    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}