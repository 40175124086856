<template>
    <div>
        <md-dialog v-model:md-active="computedModalShown" class="ebx-dialog dataset-modal modal-xl" v-show="bigModalVisible">
            <TransitionGroup tag="div" name="slide2" class="dataset-modal--transition-group">

                <div v-if="!showDatasetViewer" class="md-dialog-title md-title">
                    <div class="top-actions--container">
                        <div class="title--container">
                            <span>Select dataset</span>
                        </div>
                        <div class="close-button">
                            <md-button class="md-primary" @click="computedModalShown = false"><md-icon>close</md-icon></md-button>
                        </div>
                    </div>
                </div>

                <md-dialog-content v-if="!showDatasetViewer" :class="{'pb-0':!isShowingDatasetCatalog}">
                    <EbxTabs 
                    v-model="selectedTab" 
                    no-content-scroll 
                    @change="changedTab">
                        <EbxTab title="Dataset Catalogue" id="dataset-catalogue">
                            <DatasetList 
                                :chosen-dataset-id="chosenDatasetId"
                                :search-term="searchTerm"
                                :filter-by="filterBy" 
                                :chips="chips" 
                                :datasets="datasetCatalog" 
                                :no-datasets-text="loading ? 'Loading, please wait.':'We couldn’t find any datasets for this search'"
                                @dataset-chosen="handleChoosingDataset" 
                                @search-bar-changed="handleSearchBarChanged" 
                            />
                        </EbxTab>
                        <EbxTab title="Assets" id="assets">
                            <DatasetList 
                                :chosen-dataset-id="chosenDatasetId"
                                :search-term="searchTerm"
                                :filter-by="filterBy" 
                                :chips="chips"
                                :datasets="myDatasets" 
                                :no-datasets-text="loading ? 'Loading, please wait.':'Upload datasets to Assets to use them in your project'"
                                @dataset-chosen="handleChoosingDataset" 
                                @search-bar-changed="handleSearchBarChanged"  
                            />
                        </EbxTab>
                        <EbxTab title="Project Datasets" id="project-datasets">
                            <DatasetList 
                                :chosen-dataset-id="chosenDatasetId"
                                :search-term="searchTerm"
                                :filter-by="filterBy" 
                                :chips="chips" 
                                :allowed-global-dataset-ids="allowedGlobalDatasetIds"
                                :datasets="globalDatasetsMinusAssets" 
                                :no-datasets-text="loading ? 'Loading, please wait.':'You don’t have any project datasets. Add features to the map, or use the Save dataset for reuse block.'"
                                @dataset-chosen="handleChoosingDataset"
                                @search-bar-changed="handleSearchBarChanged" 
                            />
                        </EbxTab>
                    </EbxTabs>
                </md-dialog-content>
            </TransitionGroup>

            <Transition v-if="isShowingDatasetCatalog" name="slide" :duration="transitionDuration" :css="cssBoolean">
                <DatasetViewer 
                    v-model="showDatasetViewer" 
                    :block-dataset-id="selectedDatasetValue" 
                    :dataset-id="chosenDatasetId" 
                    :meta-data="chosenDatasetMetadata" 
                    :ebx-bands="ebxBands" 
                    :recommened-blocks="addRecommenedBlocks" 
                    @insert-dataset="handleInsertDataset" 
                    @update-recommened-blocks="updateAddRecommendedBlocks" 
                    @close-datasets-select="computedModalShown = false"
                 />
            </Transition>

            <span v-if="showActionsArea" :class="actionsClassList">
                <div v-if="selectedDatasetValue !== chosenDatasetId">
                    <md-checkbox :disabled="!hasSelectedDataset || !hasDefaultModifiers" class="ebx-checkbox" v-model="addRecommenedBlocks">Add recommended blocks</md-checkbox>
                </div>
                <EbxButton
                    :disabled="!hasSelectedDataset || loadingDataset"
                    :theme="'primary'"
                    @click="handleInsertDataset(chosenDatasetId, selectedDatasetUseModifiers)">
                    <template v-if="loadingDataset">
                        Loading Dataset
                    </template>
                    <template v-else-if="selectedDatasetValue === chosenDatasetId">
                        Okay
                    </template>
                    <template v-else-if="!this.selectedDatasetValue || this.selectedDatasetValue === 'nodata'">
                        Select dataset
                    </template>
                    <template v-else>
                        Change dataset
                    </template>
                </EbxButton>
            </span>

        </md-dialog>

        <ConfirmationModal ref="confirmReplaceBlocks" :title="modalTitle" :okButtonText="okButtonText" :ifWarning="false">
            Adding recommended blocks will remove the existing blocks in this workflow. Are you sure you want to overwrite blocks?
        </ConfirmationModal>
    </div>
</template>

<script>
import { DatasetService } from '@/services/dataset.service';
import { RegistryService } from '@/services/registry.service';
import DatasetViewer from './DatasetViewer.vue';
import DatasetList from './DatasetList.vue';
import ConfirmationModal from '../components/ConfirmationModal.vue';
import { globalEventBus } from '@/eventbus.js'
import authMixin from '@/components/mixins/authMixin.js'
import { sharedWorkflowMixin } from '@/components/mixins/projectMixin.js'
import userDatasetsMixin from '@/components/mixins/userDatasetsMixin'
import EbxTabs from '@/components/EbxComponents/EbxTabs.vue';
import EbxTab from '@/components/EbxComponents/EbxTab.vue';
import EbxButton from '@/components/EbxComponents/EbxButton.vue';
import blocklyModalsMixin from './blocklyModalsMixin.js'

export default {
    name: 'Dataset',
    mixins: [
        authMixin,
        sharedWorkflowMixin,
        userDatasetsMixin,
        blocklyModalsMixin
    ],
    components: {
        DatasetViewer,
        DatasetList,
        ConfirmationModal,  
        EbxTabs,
        EbxTab,
        EbxButton
    },
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            modalContent: [],
            chosenDatasetId: '',
            chosenDatasetMetadata: null,
            showDatasetViewer: false,
            ebxBands: [],
            blockHasChildren: false,
            datasetWatcher: null,
            globalDatasetsFromService: [],
            selectedTab: 'dataset-catalogue',
            filterBy: 'all',
            searchTerm: '',
            addRecommenedBlocks: false,
            loading: false,
            metaData: null,
            loadingDataset: false,
            selectedDatasetValue: null,
            transitionDuration: 300,
            cssBoolean: true,
            allowedGlobalDatasetIds: [], 
            modalTitle: 'Overwrite blocks?', 
            okButtonText: 'Overwrite',
            bigModalVisible: true
        }
    },
    computed: {
        actionsClassList() {
            if (this.chosenDatasetId === this.selectedDatasetValue) {
                return 'dataset-modal--bottom-actions dataset-modal--bottom-actions__right'
            } else {
                return 'dataset-modal--bottom-actions'
            }
        },
        showActionsArea() {
            return this.selectedTab !== 'dataset-catalogue'
        },
        isShowingDatasetCatalog() {
            return this.selectedTab === 'dataset-catalogue'
        },
        isShowingGlobalDatasets() {
            return this.selectedTab === 'project-datasets'
        },
        hasSelectedDataset() {
            return this.chosenDatasetId !== null && this.chosenDatasetId !== ''
        },
        selectedDatasetUseModifiers() {
            return this.hasSelectedDataset && this.hasDefaultModifiers && this.addRecommenedBlocks ? this.standardisedModifiers : []
        },
        datasetCatalog() {
            return this.modalContent.filter(dataset => dataset.location === 'core')
        },
        myDatasets() {
            const modalData = this.modalContent.filter(dataset => ['user','org'].indexOf(dataset.location) >=0)
            return modalData.concat(this.globalUploadedAssets).sort((a,b) => {
                return a.title.localeCompare(b.title)
            })
        },
        globalDatasetsMinusAssets() {
            const notAssetDatasets = this.globalDatasets.filter(dataset => dataset.origin !== 'assets_service')
            return notAssetDatasets.filter(dataset => dataset.variableType === 'area' || dataset.origin === 'workflow' || dataset.origin === 'area_service')
        },
        globalUploadedAssets() {
            return this.globalDatasets.filter(dataset => dataset.origin === 'assets_service')
        },
        globalDatasets() {
            if(this.globalDatasetsFromService) {
                return this.globalDatasetsFromService.datasets
                .map(dataset => {
                    return {
                        id: dataset.id,
                        title: dataset.title,
                        thumbnail: null,
                        keywords: [],
                        short_description: null,
                        location: 'global',
                        origin: dataset.origin,
                        type: dataset.type === 'image_collection' ? 'raster' : 'vector',
                        variableType: dataset.variable?.type || null,
                    }
                })
            }
            return [] 
        },
        chips() {
            if (this.isShowingDatasetCatalog) {
                return ['Biomass', 'Climate', 'Elevation', 'Fire', 'Forest', 'Imagery', 'Landcover', 'Radar', 'Snow', 'Water','Paid access']
            }
            return []
        },
        hasMetaData() {
            return this.metaData !== null
        },
        hasDefaultModifiers() {
            return this.hasMetaData && Array.isArray(this.metaData['ebx:default_modifiers'])
        },
        standardisedModifiers() {
            if(this.hasDefaultModifiers) {
                return this.metaData['ebx:default_modifiers']
                    .flatMap(modifiers => {
                        if (modifiers.includes("XOR")) {
                            let mods = modifiers.split(/\s+XOR\s+/)
                            return [mods[0]]
                        } else if (modifiers.match(/(OR|,)/)) {
                            let mods = modifiers.split(/\s+(?:,|OR)\s+/)
                            return [mods[0]]
                        } else if (modifiers.startsWith("NOT ")) {
                            return []
                        }
                        return [modifiers]
                    })
                    .map(type => {
                        return { type }
                    })
            }
            return []
        }
    },
    created() {
        this.globalDatasetsFromService = RegistryService.getGlobalDatasets()
    },
    beforeUnmount(){
        this.unwatchDatasetChanges()
    },
    methods: {
        getFieldValue() {
            return {
            };
        },
        async getDatafromStac() {
            try {
                this.loading = true
                this.modalContent = await DatasetService.getDatasetModalContent()
            } finally {
                this.loading = false
            }
        },
        async handleChoosingDataset(dataset) {
            try {
                this.chosenDatasetId = dataset.id
                this.loadingDataset = true
                this.metaData = await this.setDatasetMetadata(this.chosenDatasetId)
            } catch(e) {
                console.error(e)
            } finally {
                this.loadingDataset = false
                //If the currently selected dataset is an asset and the new selected dataset is not, do not 
                if (this.selectedDatasetValue.startsWith('protected_') && !this.chosenDatasetId.startsWith('protected_')) {
                    this.addRecommenedBlocks = false
                } else { 
                    if (this.metaData) {
                        this.addRecommenedBlocks = Array.isArray(this.metaData['ebx:default_modifiers']) && this.metaData['ebx:default_modifiers'].length > 0
                    }else {
                        this.addRecommenedBlocks = false
                    }
                }
                if (this.isShowingDatasetCatalog) {
                    this.showDatasetViewer = true
                }
            }
        },
        async setDatasetMetadata(datasetId) {
            this.chosenDatasetMetadata = await DatasetService.getMetadata(datasetId)
            // filter metaData for ebx:display = true bands and bands with ebx:name
            if(this.chosenDatasetMetadata && this.chosenDatasetMetadata.bands) {
                this.ebxBands = []
                this.chosenDatasetMetadata.bands.forEach((band) => {
                    if (band['ebx:display'] === true && band['ebx:name']) {
                        this.ebxBands.push(band)
                    }
                })
            }
            return this.chosenDatasetMetadata
            
        },
        async handleInsertDataset(datasetId, standardisedModifiers) {
            //Used to close the modal but that caused the entire vue app to unmount and we'd lose the confirmation modal
            // this.computedModalShown = false 
            
            if (datasetId === this.selectedDatasetValue) {
                this.computedModalShown = false
                return
            }
            if (standardisedModifiers === null) {
                standardisedModifiers = this.standardisedModifiers
            }
            if (standardisedModifiers.length > 0 && this.blockHasChildren) {
                this.bigModalVisible = false
                const confirmed = await this.$refs.confirmReplaceBlocks.confirm()
                if (confirmed === false) {
                    this.computedModalShown = false
                    return
                }
                this.computedModalShown = false
            }
            this.blockyEventCallback('insert-dataset', datasetId, standardisedModifiers)
            this.computedModalShown = false
        },
        updateAddRecommendedBlocks(value) {
            this.addRecommenedBlocks = value
        },
        watchDatasetForChanges() {
            if (this.datasetWatcher === null) {
                this.datasetWatcher = DatasetService.serviceUpdated$.subscribe(() => {
                    this.getDatafromStac()
                })
            }
        },
        unwatchDatasetChanges() {
            if (this.datasetWatcher !== null) {
                this.datasetWatcher.unsubscribe()
                this.datasetWatcher = null
            }
        },
        handleSearchBarChanged(searchBar) {
            this.filterBy = searchBar.filterBy
            this.searchTerm = searchBar.searchInput
        },
        changedTab() {
            this.chosenDatasetId = null
            this.addRecommenedBlocks = false
        },
        findModalTab(datasetId) {
            // check if datasetId is in datasetCatalog
            const datasetCatalog = this.datasetCatalog.find(dataset => dataset.id === datasetId)
            if (datasetCatalog) {
                return 'dataset-catalogue'
            }
            // check if datasetId is in myDatasets
            const myDatasets = this.myDatasets.find(dataset => dataset.id === datasetId)
            if (myDatasets) {
                return 'assets'
            }
            // check if datasetId is in project datasets
            const projectDatasets = this.globalDatasetsMinusAssets.find(dataset => dataset.id === datasetId)
            if (projectDatasets) {
                return 'project-datasets'
            }
        },
        removeAnimation() {
            this.transitionDuration = 0
            this.cssBoolean = false
        },
        addAnimation() {
            this.transitionDuration = 300
            this.cssBoolean = true
        }
    },
    watch: {
        showModal: {
            async handler(val) {
                this.addAnimation()
                globalEventBus.$emit('supressDeleteAction', val)
                this.showDatasetViewer = false
                if (!val) {
                    this.blockyEventCallback('modal-close')
                    this.searchTerm = ''
                    this.addRecommenedBlocks = false
                    this.chosenDatasetId = null
                    this.unwatchDatasetChanges()
                } else {
                    if (this.modalContent.length === 0) {
                        await this.getDatafromStac()
                    }
                    this.watchDatasetForChanges()
                    if (this.selectedDatasetValue && this.selectedDatasetValue !== 'nodata') {
                        await this.setDatasetMetadata(this.selectedDatasetValue)
                        this.chosenDatasetId = this.selectedDatasetValue
                        this.selectedTab = this.findModalTab(this.chosenDatasetId)
                        this.removeAnimation()
                        if (this.selectedTab === 'dataset-catalogue') {
                            this.showDatasetViewer = true
                        }
                    }
                }
            }
        },
        showDatasetViewer(shown) {
            if (!shown) {
                this.addRecommenedBlocks = false
            }
        },
        selectedDatasetValue: {
            async handler(val) {
                if (val && val !== 'nodata') {
                    if (this.chosenDatasetId !== val) {
                        await this.handleChoosingDataset({id: val})
                    }
                    return val
                } else {
                    return null                
                }
            }
        }
    }
}
</script>

