<template>
  <div>
    <div>
        <h2 class="ebx-header-4 mb-2">Earth Blox Packages</h2>
        <p class="ebx-primary mb-6">Choose which packages published by Earth Blox appear on the Explore page for your organisation.</p>
        <OrganisationSettingsPackagesForm @change="unsavedSettingsChanges = $event" />
    </div>
</div>
</template>

<script>
import OrganisationSettingsPackagesForm from './OrganisationSettingsPackagesForm.vue';
export default {
  name: "OrganisationSettings",
  data() {
    return {
      unsavedSettingsChanges: false,
      unsavedChangesMessage: 'You have unsaved changes. Are you sure you want to leave?',
      unsavedChangesTitle: 'Unsaved Changes',
      unsavedChangesButtonText: 'Leave'
    }
  },
  components: {
    OrganisationSettingsPackagesForm
  },
  async beforeRouteLeave(to, from, next) {
    if(this.unsavedSettingsChanges) {
        const confirmedLeave = await this.$confirmModal(this.unsavedChangesMessage ,{
            title: this.unsavedChangesTitle,
            okButtonText: this.unsavedChangesButtonText,
        })
        if (confirmedLeave === false) {
            return
        }
    }
    next();
  },
  watch: {
    unsavedSettingsChanges(changes) {
      this.$store.commit('global/setBrowserConfirmUnload', changes)
    }
  },
  beforeUnmount() {
    this.$store.commit('global/setBrowserConfirmUnload', false)
  }
};
</script>