<template>
    <v-row class="layout--side-menu" no-gutters>
        <v-col cols="4" lg="2" sm="3" class="layout--side-menu--list" :class="menuClass">
            <slot name="menu">
                <v-list-item v-if="!isExplorer" link to="/projects" :active="currentPage === 'projects'" class="ebx-primary">Projects</v-list-item>
                <v-list-item link to="/projects/explore" :active="currentPage === 'explore'" class="ebx-primary">Explore</v-list-item>
                <v-list-item link to="/projects/assets" :active="currentPage === 'assets'" class="ebx-primary">Assets</v-list-item>
                <v-list-item link to="/projects/exports" :active="currentPage === 'exports'" class="ebx-primary">Exports</v-list-item>
            </slot>
        </v-col>
        <v-col cols="8" lg="10" sm="9" :class="contentContainerClass" class="mt-9">
            <div class="layout--side-menu--content-container">
                <div class="layout--side-menu--content" :class="contentClassCheckWidth">
                    <slot></slot>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import authMixin from '@/components/mixins/authMixin';
import { isNotNullOrUndefined } from '@/helpers/generalHelperFunctions.js'

export default {
    props: {
        menuClass: {
            type: String,
            default: ''
        },
        contentContainerClass: {
            type: String,
            default: ''
        },
        contentClass: {
            type: String,
            default: ''
        },
        fullWidth: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentPage: '',
            currentPath: '',
        }
    },
    computed: {
        contentClassCheckWidth() {
            return this.fullWidth ? 'layout--side-menu--content--full-width' : '';
        }
    },
    mixins: [authMixin],
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (isNotNullOrUndefined(to)) { 
                    const pathEls = to.path.split('/');
                    this.currentPath = to.path;
                    if (pathEls.findIndex(el => el === 'assets') > -1) {
                        this.currentPage = 'assets';
                    } else if (pathEls.findIndex(el => el === 'exports') > -1) {
                        this.currentPage = 'exports';
                    } else if (pathEls.findIndex(el => el === 'dataset') > -1) {
                        this.currentPage = 'assets';
                    } else if (pathEls.findIndex(el => el === 'explore') > -1) {
                        this.currentPage = 'explore';
                    } else if (pathEls.findIndex(el => el === 'projects') > -1) {
                        this.currentPage = 'projects';
                    } else {
                        this.currentPage = '';
                    }
                }
            },
        },
    }
}
</script>
