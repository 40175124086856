<template>
    <div class="ebx-search-bar">
        <div class="ebx-search-bar--layout-item ebx-search-bar--search-container">
            <slot>
                <div class="ebx-search-bar--search-input">
                    <v-text-field 
                        :placeholder="placeholder" 
                        prepend-inner-icon="mdi-magnify" 
                        variant="solo-filled" 
                        aria-label="Search"
                        density="comfortable"
                        v-model="searchInput" 
                        @keydown="$emit('keydown', $event)" 
                        @keyup="$emit('keyup', $event)"
                        flat
                        hide-details="true"
                        :rounded="hasHideOptions"
                        />
                </div>
            </slot>
        </div>
        <div v-if="hasFilterOptions && hideOptions === false" class="ebx-search-bar--layout-item ebx-search-bar--option-container">
            <div class="ebx-search-bar--filter-inline rounded-0">
                <v-select
                    v-model="filterBy"
                    :items="filterOptions"
                    item-title="label" 
                    item-value="value"
                    density="comfortable"
                    variant="solo-filled" 
                    flat
                    hide-details="true"
                    rounded="s-0 e-lg"
                    :min-width="selectMinWidth"
                />
                <span ref="textMeasurer" class="text-measurer">{{ longestOption }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import valueMixin from '@/components/mixins/valueMixin'
export default {
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        filterOptions: {
            type: Array,
            default: null
        },
        hideOptions: {
            type: Boolean,
            default: false
        }, 
        placeholder: {
            type: String,
            default: 'Search'
        },
    },
    data() {
        return {
            selectWidth: 0,
        }
    },  
    mixins: [valueMixin],
    emits: [
        'update:modelValue',
        'keydown',
        'keyup'
    ],
    mounted() {
        this.selectWidth = this.measureText();
    },
    methods: {
        measureText() {
            if (!this.filterOptions || !this.$refs.textMeasurer) return 0;
            return this.$refs.textMeasurer.offsetWidth + 64;
        }
    },
    computed: {
        hasFilterOptions() {
            return this.filterOptions !== null
        },
        form: {
            get() {
                return this.value
            },
            set(form) {
                this.$emit('update:modelValue', form)
            }
        },
        searchInput: {
            get() {
                return this.form?.searchInput || ''
            },
            set(value) {
                this.form = Object.assign({}, this.form, {
                    searchInput: value
                })
            }
        },
        filterBy: {
            get() {
                return this.form?.filterBy || 'all'
            },
            set(value) {
                this.form = Object.assign({}, this.form, {
                    filterBy: value
                })
            }
        },
        longestOption() {
            if (!this.filterOptions) return '';
            const longestOption = this.filterOptions.reduce((a, b) => a.label.length > b.label.length ? a : b);
            return longestOption.label;
        },
        selectMinWidth() {
            if (!this.filterOptions || this.selectWidth === 0) return '0px';
            return this.measureText() + 'px';
        }, 
        hasHideOptions() { 
        if (!this.hasFilterOptions || this.hideOptions) { 
            return 's-lg e-lg'
        } else { 
            return 's-lg e-0'
        }
        }
    },
}
</script>