/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/**
 * This file provides access to Firebase Functions deployed in the cloud, for the frontend UI.
 *
 * The functions are imported into front end code.
 * Any logging performed here will appear in the user's browser's JS console.
 * Note also that therefore we have no control over environment variables.
 */
import { app, auth, firebaseConfig, LOAD_BALANCER_DOMAIN } from "@/firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

// Logic to establish which region to deploy the Firebase functions to
// or to establish whether a load balancer is in use
var REGION = 'europe-west2';

if (firebaseConfig) {
  // in the unit tests firebaseConfig is undefined, hence the need for this 'if'
  REGION = firebaseConfig.locationId
}

if (REGION == 'europe-west'){
  // special case - functions have to be in a specific region
  // europe-west is a possible Firebase configuration, but not a real region
  // this is used for the ebx-standby server, which has to not be europe-west2
  REGION = 'europe-west1'
}

const regionalFunctions = getFunctions(app, REGION);
if (LOAD_BALANCER_DOMAIN && !import.meta.env.DEV){
    regionalFunctions.customDomain = LOAD_BALANCER_DOMAIN;
}

/** get the URL from the firebase regional function service */
const regionalFunctionUrl = function(name) {
  return regionalFunctions._url(name);

  // Old implementation
  // This replicates old functionality in the Firebase SDK that was removed in v9.
  // https://github.com/firebase/firebase-js-sdk/blob/ae319059e4a083a613a2999c1e61d44c9053a0d7/packages/functions/src/api/service.ts#L113

  // const  projectId = app.options.projectId;
  // const region = regionalFunctions._region || REGION;
  // if (regionalFunctions._delegate.emulatorOrigin !== null) {
  //   const origin = regionalFunctions._delegate.emulatorOrigin;
  //   return `${origin}/${projectId}/${region}/${name}`;
  // }
  // return `https://${region}-${projectId}.cloudfunctions.net/${name}`;
}

const functions = {
    // js functions (firebase)
    getAllUsers: httpsCallable(regionalFunctions, 'getAllUsers'),
    addUser: httpsCallable(regionalFunctions, 'addUser'),
    registerUser: httpsCallable(regionalFunctions, 'registerUser'),
    updateUser: httpsCallable(regionalFunctions, 'updateUser'),
    deleteUser: httpsCallable(regionalFunctions, 'deleteUser'),
    acceptTerms: httpsCallable(regionalFunctions, 'acceptTerms'),
    earthTalks: httpsCallable(regionalFunctions, 'earthTalks'),
    getBasemaps: httpsCallable(regionalFunctions, 'getBasemaps'),
    orgUserGroups: httpsCallable(regionalFunctions, 'orgUserGroups'),
    explainRunWorkflow: httpsCallable(regionalFunctions, 'explainRunWorkflow'),
    completeSSOSignup: httpsCallable(regionalFunctions, 'completeSSOSignup'),

    // Python functions
    getTable: httpsCallable(regionalFunctions, 'getTable'),
    getCountryTable: httpsCallable(regionalFunctions, 'getCountryTable'),
    validateExpression: httpsCallable(regionalFunctions, 'validateExpression'),
    getThumbnail: (data) => postDataBlob(regionalFunctions._url('getThumbnail'), { "data": data }),
    generateChart: httpsCallable(regionalFunctions, 'generateChart'),
    // The old Python completeSSOSignup function is deprecated; we use the JS one instead, defined above.
    // This could be reinstated if we add the missing code, but we would then be duplicating the functionality available in the JS functions.
    //completeSSOSignup: (data) => postData(regionalFunctions.regionalFunctionUrl('completeSSOSignup'), { "data": data }),
    sso_get_record_for_email: httpsCallable(regionalFunctions, 'sso_get_record_for_email'),
    sso_get_record_for_org: httpsCallable(regionalFunctions, 'sso_get_record_for_org'),
    gmpSaasRegister: httpsCallable(regionalFunctions, 'gmpSaasRegister'),
    get_pixel_value: (data) => postData(regionalFunctions._url('get_pixel_value'), { "data": data }),
    extract_workflow_log: (data) => postDataBlob(regionalFunctions._url('extract_workflow_log'), { "data": data }),

}






/**
 * emulator ports lookup table for functions framework
 */
const functionEmulatorPorts = {
  getTable: 5051,
  getCountryTable: 5053,
  validateExpression: 5056,
  getThumbnail: 5057,
  // 5060 and 5061 are restricted 
  apiManageDataset: 5062,
  generateChart: 5063,
  completeSSOSignup: 5064,
  sso_get_record_for_email: 5065,
  sso_get_record_for_org: 5066,
  gmpSaasRegister: 5067,
  get_pixel_value: 5068,
  extract_workflow_log: 5069,
}

/**
 * simple HTTP POST request
 * @param {String} url url for request to be made
 * @param {Object} data - object to be sent in request
 * @returns
 */

const getUserAuthToken = async (waitForValidUser = false) => {
  if(auth.currentUser) {
    return auth.currentUser.getIdToken()
  }
  return new Promise((resolve) => {
    onAuthStateChanged(auth, async function(user) {
      if(waitForValidUser) {
        if (user) {
          resolve(user ? await user.getIdToken() : null)
        }
      } else {
        resolve(user ? await user.getIdToken() : null)
      }
    })
  })
}

async function postData(url, data) {
  const authToken = await getUserAuthToken()

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authToken
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  }).then(
    async response => {
      // https://github.com/whatwg/fetch/issues/113
      if (response.status == 204) return '';
      const responseJson = await response.json()
      if(!responseJson.data) {
        throw new Error("Response is missing data field.")
      }
      return responseJson
    });
}

async function postDataBlob(url, data) {
  const authToken = await getUserAuthToken()

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authToken
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  }).then(
    async response => {
      // https://github.com/whatwg/fetch/issues/113
      if (response.status == 204) return '';
      return response;
    });

}

/**
 * Replaces functions from emulators to point towards fetch requests
 * to functions framework
 */
function setupPythonHTTPSEmulators() {
  functions.getTable = (data) => {
    let body = { "data": data }
    return postData(`http://localhost:${functionEmulatorPorts.getTable}/`, body);
  }
  functions.getCountryTable = (data) => {
    let body = { "data": data }
    return postData(`http://localhost:${functionEmulatorPorts.getCountryTable}/`, body);
  }
  functions.validateExpression = (data) => {
    let body = { "data": data }
    return postData(`http://localhost:${functionEmulatorPorts.validateExpression}/`, body);
  }
  functions.getThumbnail = (data) => {
    let body = { "data": data }
    return postDataBlob(`http://localhost:${functionEmulatorPorts.getThumbnail}/`, body);
  }
  functions.generateChart = (data) => {
    let body = { "data": data }
    return postData(`http://localhost:${functionEmulatorPorts.generateChart}/`, body);
  }
  functions.completeSSOSignup = (data) => {
    let body = { "data": data }
    return postData(`http://localhost:${functionEmulatorPorts.completeSSOSignup}/`, body);
  }
  functions.sso_get_record_for_email = (data) => {
    let body = { "data": data }
    return postData(`http://localhost:${functionEmulatorPorts.sso_get_record_for_email}/`, body);
  }
  functions.sso_get_record_for_org = (data) => {
    let body = { "data": data }
    return postData(`http://localhost:${functionEmulatorPorts.sso_get_record_for_org}/`, body);
  }
  functions.gmpSaasRegister = (data) => {
    let body = { "data": data };
    // the postData method above does auth, and swallows the http status, not not suitable for this particular function
    return fetch(`http://localhost:${functionEmulatorPorts.gmpSaasRegister}/`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 
        'Content-Type': 'application/json'
      }, 
      body: JSON.stringify(body)
    });
  }
  functions.get_pixel_value = (data) => {
    let body = { "data": data }
    return postData(`http://localhost:${functionEmulatorPorts.get_pixel_value}/`, body);
  },
  functions.extract_workflow_log = (data) => {
    let body = { "data": data }
    return postDataBlob(`http://localhost:${functionEmulatorPorts.extract_workflow_log}/`, body);
  }
}

// Use the functions emulator if in development mode
if (import.meta.env.DEV || location.hostname === 'localhost') {
  console.info("Dev environment: Using functions emulator");
  connectFunctionsEmulator(regionalFunctions, "localhost", 5001);
  // Python functions emulator
  setupPythonHTTPSEmulators();
}


const api = {
  url: (functionName) => {
    if ((import.meta.env.DEV || location.hostname === 'localhost') && functionEmulatorPorts[functionName]) {
      return `http://localhost:${functionEmulatorPorts[functionName]}`
    }
    return regionalFunctionUrl(functionName)
  },
  post: async (url, data, queryParams={}) => {
    if (Object.keys(queryParams).length > 0) {
        url += '?' + (new URLSearchParams(queryParams))
    }

    const authToken = await getUserAuthToken()
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    })
  },
  put: async (url, data, queryParams={}) => {
    if (Object.keys(queryParams).length > 0) {
        url += '?' + (new URLSearchParams(queryParams))
    }
    const authToken = await getUserAuthToken()
    return fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    })
  },
  get: async (url, queryParams={}) => {
    if (Object.keys(queryParams).length > 0) {
        url += '?' + (new URLSearchParams(queryParams))
    }
    const authToken = await getUserAuthToken()
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 
        'Authorization': 'Bearer ' + authToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
  },
  delete: async (url, queryParams={}) => {
    if (Object.keys(queryParams).length > 0) {
        url += '?' + (new URLSearchParams(queryParams))
    }
    const authToken = await getUserAuthToken()
    return fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 
        'Authorization': 'Bearer ' + authToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
  }
}

export {
  functions,
  api,
  getUserAuthToken
}
