<template>
    <div>
        <h1 class="d-none">Explore</h1>
        <div v-if="loadingExplore">
            <md-progress-spinner 
                md-mode="indeterminate" 
                class="static-loading-spinner md-accent assets-tab--loading"
                :md-diameter="80"
                ></md-progress-spinner>
        </div>
        <div v-else>
            <v-tabs
                v-model="pageTab"
                class="border-opacity border-b-sm mb-4">
                <v-tab value="packages-tab"><h2 class="ebx-header-1 px-1">{{ packagesTitle }}</h2></v-tab>
                <v-tab value="templates-tab"><h2 class="ebx-header-1 px-1">{{ projectsTitle }}</h2></v-tab>
            </v-tabs>

            <v-tabs-window v-model="pageTab">
                <v-tabs-window-item :transition="false" :reverse-transition="false" value="packages-tab">
                    <v-menu v-if="isSuperAdmin">
                        <template v-slot:activator="{ props }">
                            <v-btn variant="text" v-bind="props" color="primary" prepend-icon="mdi-plus">Create new package</v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="handleNewPackage(packageTypes.TEAM)">
                                <v-list-item-title class="text-body-2">Team package</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click="handleNewPackage(packageTypes.GLOBAL)">
                                <v-list-item-title class="text-body-2">Earth Blox package</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn v-else-if="isOrgGroupAdmin" @click="handleNewPackage(packageTypes.TEAM)" variant="text" color="primary" prepend-icon="mdi-plus">Create new package</v-btn>
                    <EbxSearchBar 
                        v-model="form" 
                        :filter-options="filterOptionsCheckingGlobalPackages"
                        class="filter-wide py-3"
                    />
                    <v-container class="pa-1">
                        <v-row dense>
                            <v-col 
                                cols="12" 
                                md="4" 
                                sm="6" 
                                xs="12" 
                                v-for="pkg in filteredPackages" 
                                :key="pkg.id">
                                <EbxPackageCard :details="{
                                    title: pkg.title,
                                    description: pkg.description,
                                    images: pkg.projectsAssigned.projectThumbnails,
                                    isEbxPackage: pkg.type === 'global'
                                    }" @goToPage="() => packageOpen(pkg.id)" />
                            </v-col>
                        </v-row>  
                    </v-container>  
                    <div class="assets-tab--empty" v-if="filteredPackages.length === 0 && !form.searchInput && teamPackages.length === 0">
                        <div class="ebx-icon">
                            <img :src="assets.icons.emptyStateAssets" alt="No team packages">
                        </div>
                        <p class="ebx-primary">Your organisation doesn’t have any team packages.</p>
                        <p class="ebx-primary"><a href="https://support.earthblox.io/support/solutions/articles/101000543049-packages-in-earth-blox" target="_blank">Learn more about creating packages</a></p>
                    </div>
                    <div class="assets-tab--empty" v-else-if="filteredPackages.length === 0">
                        <div class="ebx-icon">
                            <img :src="assets.icons.emptyStateAssets" alt="No packages">
                        </div>
                        <p class="ebx-primary">We couldn't find any packages matching this search</p>
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item value="templates-tab" :transition="false" :reverse-transition="false">
                    <EbxTemplateWorkflows 
                        :title="projectsTitle" 
                        v-model="loadingTemplates"/>
                </v-tabs-window-item>
            </v-tabs-window>
        </div>
    </div>
</template>

<script>
import EbxTemplateWorkflows from '@/components/EbxComponents/EbxTemplateWorkflows.vue'
import EbxPackageCard from '@/components/EbxComponents/EbxPackageCard.vue'
import EbxSearchBar from '../../components/EbxComponents/EbxSearchBar.vue';
import authMixin from "@/components/mixins/authMixin";
import packageMixin from "@/components/mixins/packageMixin";
import { packageTypes } from '../../constants/appConstants';
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
    components: {
        EbxTemplateWorkflows,
        EbxPackageCard,
        EbxSearchBar
    },
    mixins: [authMixin, packageMixin, assetsMixin],
    data() {
        return {
            projectsTitle: "Templates",
            packagesTitle: "Packages",
            loadingTemplates: false,
            loadingGlobalPackages: false, 
            loadingTeamPackages: false,
            pageTab: 'packages',
            packageTypes,
            showPackageCreation: false,
            form: {
                searchInput: null,
                filterBy: 'all'
            }, 
            filterOptions: [
                {
                    value: 'all',
                    label: 'All'
                },
                {
                    value: 'team',
                    label: 'Team'
                },
                {
                    value: 'global',
                    label: 'Earth Blox'
                }
            ]
        }
    },
    computed: {
        filterOptionsCheckingGlobalPackages() {
            return this.globalPackages.length > 0 ? this.filterOptions : null
        },
        loadingExplore() {
            return this.loadingPackages;
        },
        loadingPackages() {
            return this.loadingGlobalPackages || this.loadingTeamPackages;
        },
        allPackages() {
            let allPackages = [];
            if (!this.globalPackages && !this.teamPackages) {
                // No packages, return empty array
                return [];
            } else if (!this.globalPackages) {
                // No global packages, return team packages
                allPackages = this.teamPackages;
            } else if (!this.teamPackages) {
                // No team packages, return global packages
                allPackages = this.globalPackages;
            } else {
                // Both global and team packages exist, combine them
                allPackages = [...this.globalPackages, ...this.teamPackages];
            }
            return allPackages;
        },
        filteredPackages() {
            let filteredPackages = this.allPackages;

            if (this.form.filterBy !== 'all') {
                filteredPackages = filteredPackages.filter(pkg => {
                    return pkg.type === this.form.filterBy;
                });
            } 

            // take into account the search input
            if (this.form.searchInput !== "" && this.form.searchInput !== null) {
                filteredPackages = filteredPackages.filter(pkg => {
                    return pkg.title.toLowerCase().includes(this.form.searchInput.toLowerCase());
                });
            }
            // sort the packages alphabetically by title
            return filteredPackages.slice().sort((a, b) => a.title.localeCompare(b.title));
        }
    },
    mounted() {
        this.getPackages();
    },
    methods: {
        async getPackages() {
            this.loadingGlobalPackages = true 
            this.loadingTeamPackages = true
            await this.getGlobalPackages();
            await this.getTeamPackages(this.user.orgId);
            this.loadingGlobalPackages = false
            this.loadingTeamPackages = false
        },
        packageOpen(id) {
            const selectedPackage = this.allPackages.find(pkg => pkg.id === id);
            this.$router.push( {
                name:'Package',
                params: {
                    packageId: selectedPackage.id,
                }
            })
        },
        handleNewPackage(type) {
            this.setShowPackageModal(true)
            this.setPackageModalType(type)
            this.setAddNewPackage(true)
        },
    },
}
</script>
